import React, { useEffect, useState } from "react";
import "./add-to-homescreen.scss";
import PWAPrompt from "react-ios-pwa-prompt";
import { isMobile, isAndroid, isIOS } from "react-device-detect";

export default function AddToHomescreen() {
  const [showIOSPrompt, setShowIOSPrompt] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      window.deferredPrompt = e;
    });
  }, []);

  return isMobile ? (
    <>
      <button
        className="add-to-homescreen"
        onClick={() => {
          installApp();
        }}
      >
        <i className="fa fa-plus-circle" />
        Add To Homescreen
      </button>
      {/* IOS Prompt */}
      {showIOSPrompt && (
        <PWAPrompt
          copyClosePrompt="Close"
          onClose={() => {
            setShowIOSPrompt(false);
          }}
        />
      )}
    </>
  ) : null;

  function installApp() {
    // If android
    if (isAndroid) window.deferredPrompt.prompt();
    // If IOS
    else if (isIOS) setShowIOSPrompt(true);
  }
}
