import React, { Component } from "react";
import "./App.scss";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logIn, logOut, setUser } from "actions/user-actions";
import HomePage from "./pages/HomePage/home-page";
import LoginPage from "./pages/LoginPage/login-page";
import ChooseMapCategory from "./pages/CreateMap/ChooseMapCategory.jsx/choose-map-category";
import Api from "./utils/api";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReactGA from "react-ga";
import EnterMainGoal from "./pages/CreateMap/EnterMainGoal/enter-main-goal";
import EnterSteps from "./pages/CreateMap/EnterSteps/enter-steps";
import EnterObstaclesAndOvercomes from "./pages/CreateMap/EnterObstaclesAndOvercomes/enter-obstacles-and-overcomes";
import MapPage from "./pages/Map/map-page";
import ChooseMapImage from "./pages/CreateMap/ChooseMapImage/choose-map-image";
import Questions from "./pages/CreateMap/Questions/questions";
import AdminHomePage from "./pages/Admin/HomePage/admin-home-page";
import ManageUsers from "./pages/Admin/ManageUsers/manage-users";
import SettingsPage from "./pages/SettingsPage/settings-page";
import { withTranslation } from "react-i18next";
import { changeLanguageCode } from "actions/settings-actions";

ReactGA.initialize("UA-XXXXXXXX-1", { debug: true });
class App extends Component {
  state = {
    ini: false,
    //isLoggedIn: false,
  };
  Ini = () => {
    Api.get("CheckLogin", { withCredentials: true })
      .then((res) => {
        const {
          data: { Success, Payload, Message, ErrorCode },
        } = res;
        if (Success) {
          this.props.setUser(Payload);
          this.props.logIn();
          this.setState({
            ini: true,
          });
        } else this.props.logOut();
        this.setState({
          ini: true,
        });
      })
      .catch((error) => {
        this.props.logOut();
        this.setState({
          ini: true,
        });
      });
  };
  componentDidMount() {
    this.Ini();
    this.setLang(this.props.langCode);
  }

  setLang = (code) => {
    var checkUserLang = localStorage.getItem("homemap-lang");
    if (checkUserLang) {
      // console.log("localStorage lang: ", checkUserLang);
      code = checkUserLang;
    }
    // console.log("code: ", code);
    localStorage.setItem("homemap-lang", code);
    this.props.i18n.changeLanguage(code);
    this.props.changeLanguageCode(code);
  };

  render() {
    const t = this.props.t;
    return (
      this.state.ini && (
        <div className={this.props.langCode === "he" ? "rtl" : "ltr"}>
          <GoogleReCaptchaProvider reCaptchaKey="6LemptQZAAAAABLzFw0U56kP1_ewslfojQxeNuA7">
            <HashRouter>
              <Switch>
                <Route
                  exact
                  path={["/", "/otp/:otpcode?"]}
                  render={(props) =>
                    this.props.isLoggedIn ? (
                      <Redirect
                        to={this.props.isAdmin ? "/admin/home" : "/home"}
                      />
                    ) : (
                      <LoginPage {...props} />
                    )
                  }
                />

                {/* Admin Routes */}
                <Route
                  path={"/admin/home"}
                  render={(props) =>
                    !this.props.isLoggedIn || !this.props.isAdmin ? (
                      <Redirect to="/" />
                    ) : (
                      <AdminHomePage {...props} />
                    )
                  }
                />

                <Route
                  path={"/admin/users"}
                  render={(props) =>
                    !this.props.isLoggedIn || !this.props.isAdmin ? (
                      <Redirect to="/" />
                    ) : (
                      <ManageUsers {...props} />
                    )
                  }
                />

                {/* User Routes */}
                <Route
                  path="/home"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <HomePage {...props} />
                    )
                  }
                />

                <Route
                  path="/create-map/choose-category/:edit?"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <ChooseMapCategory {...props} />
                    )
                  }
                />

                <Route
                  path="/create-map/questions"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <Questions {...props} />
                    )
                  }
                />

                <Route
                  path="/settings"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <SettingsPage {...props} />
                    )
                  }
                />

                <Route
                  path="/create-map/enter-main-goal/:edit?"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <EnterMainGoal {...props} />
                    )
                  }
                />
                <Route
                  path="/create-map/choose-map-image/:edit?"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <ChooseMapImage {...props} />
                    )
                  }
                />
                <Route
                  path="/create-map/enter-steps/:edit?"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <EnterSteps {...props} />
                    )
                  }
                />
                <Route
                  path="/create-map/enter-obstacles-and-overcomes/:edit?"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <EnterObstaclesAndOvercomes {...props} />
                    )
                  }
                />

                <Route
                  path="/map/:id"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <MapPage {...props} />
                    )
                  }
                />

                <Route
                  path="/forum"
                  render={(props) =>
                    !this.props.isLoggedIn ? (
                      <Redirect to="/" />
                    ) : (
                      <HomePage {...props} />
                    )
                  }
                />
              </Switch>
            </HashRouter>
          </GoogleReCaptchaProvider>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.userReducer.isLoggedIn,
    isAdmin: state.userReducer.user.isAdmin,
    langCode: state.settingsReducer.langCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logIn,
      logOut,
      setUser,
      changeLanguageCode,
    },
    dispatch
  );
};
export default withTranslation("Common")(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
