import React, { Component, useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logIn, logOut, setUser } from "actions/user-actions";
import { changeLanguageCode } from "actions/settings-actions";
import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import "./login-page.scss";
import Api from "../../utils/api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SplashImage from "../../components/SplashImage/splash-image";
import { useTranslation } from "react-i18next";
import AddToHomescreen from "../../components/AddToHomescreen/add-to-homescreen";

const LoginPage = (props) => {
  const isLoggedIn = useSelector((state) => state.userReducer.isLoggedIn);
  const langCode = useSelector((state) => state.settingsReducer.langCode);
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("1"); // was 972 (israel)
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation("LoginPage");

  const i18nChangeLanguage = (lng) => i18n.changeLanguage(lng);

  //const AppData = useContext(AppDataContext);

  let matchOTP = useRouteMatch({
    path: "/otp",
    strict: true,
    sensitive: true,
  });
  let matchOTPcode = useRouteMatch({
    path: "/otp/:otpcode",
    strict: true,
    sensitive: true,
  });
  useEffect(() => {
    if (matchOTPcode) {
      setOtp(matchOTPcode.params.otpcode);
      Otp(matchOTPcode.params.otpcode);
      //alert();
    }
    initLang();
  }, []);
  const Login = async () => {
    //console.log("get Token...");

    setLoading(true);
    setShowError(false);
    let token = "asd";
    try {
      token = await executeRecaptcha("login_page");
    } catch (err) {
      //console.log(err);
    }

    //console.log("token", token);
    Api.post("Login/Form", {
      PhoneNumber: phoneNumber.slice(countryCode.length), //phoneNumber.slice(countryCode.length),
      CountryCode: countryCode, //countryCode,
      "g-recaptcha-response": token,
    }).then(async (res) => {
      //console.log("sent otp");
      const {
        data: { Success, Payload, Message, ErrorCode },
      } = res;
      setLoading(false);
      if (Success) {
        props.history.push("/otp");
        if ("OTPCredential" in window) {
          navigator.credentials
            .get({
              otp: {
                transport: ["sms"],
              },
            })
            .then((get_otp) => {
              if (get_otp != null) {
                setOtp(get_otp.code);
                Otp(get_otp.code);
              }
            });
          //alert(content);
        }
      } else {
        setShowError(true);

        if (ErrorCode === 5) {
          setErrorText(t("errors.incorrect-number"));
          //setPhoneNumber("");
        } else if (ErrorCode === 901) {
          setErrorText(t("errors.recaptcha-verification-failed"));
        }
      }
    });
  };
  ///choose-error-category
  const Otp = async (otpCode) => {
    setShowError(false);
    setLoading(true);
    let token = "asd";
    try {
      token = await executeRecaptcha("login_page");
    } catch (err) {
      //console.log(err);
    }
    Api.post("Login/OTP", {
      otp: otpCode,
      "g-recaptcha-response": token,
    }).then((res) => {
      const {
        data: { Success, Payload, Message, ErrorCode },
      } = res;
      setLoading(false);
      if (Success) {
        //localStorage.setItem("name", Payload);
        //AppData.goINI();
        dispatch(setUser(Payload));
        dispatch(logIn());
        props.history.push("/");
      } else {
        setShowError(true);

        if (ErrorCode === 5) {
          setErrorText(t("errors.incorrect-number"));
          setPhoneNumber("");
        } else if (ErrorCode === 901) {
          setErrorText(t("errors.recaptcha-verification-failed"));
        } else if (ErrorCode === 130) {
          setErrorText(t("errors.wrong-code"));
        } else if (ErrorCode === 131) {
          setErrorText(t("errors.expired-code"));
          setOtp("");
          props.history.push("/");
        }
      }
    });
  };

  const initLang = () => {
    let code = props.langCode;
    console.log("init code: ", code);
    var checkUserLang = localStorage.getItem("homemap-lang");
    if (checkUserLang) {
      console.log("localStorage lang: ", checkUserLang);
      code = checkUserLang;
    }
    console.log("code: ", code);
    localStorage.setItem("homemap-lang", code);
    i18nChangeLanguage(code);
    dispatch(changeLanguageCode(code));
    console.log("langCode: ", langCode);
  };

  const setLang = (code) => {
    localStorage.setItem("homemap-lang", code);
    i18nChangeLanguage(code);
    dispatch(changeLanguageCode(code));
    console.log("langCode: ", langCode);
  };

  return (
    <div className="login-page">
      <AddToHomescreen />

      {/* <SplashImage /> */}

      <div className="upper-section">
        <img className="logo" src={require("images/logo.png")} />
      </div>

      <div className="lower-section">
        <div className="content">
          {showError && <div className="login-error">{errorText}</div>}
          {!matchOTP && (
            <PhoneInput
              disabled={loading}
              containerClass="phone"
              inputClass="phone-input"
              buttonClass="phone-button"
              dropdownClass="phone-select"
              enableSearch={true}
              countryCodeEditable={false}
              country={"us"}
              value={phoneNumber}
              onChange={(phone, country) => {
                setCountryCode(country.dialCode);
                setPhoneNumber(phone);
              }}
            />
          )}
          {/*<Input
            type="tel"
            style={{ fontSize: "20px", textAlign: "center" }}
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => {
              const val = e.target.value;
              setPhoneNumber(val);
            }}
          />*/}
          {matchOTP && (
            <Input
              autoFocus
              type="number"
              style={{ fontSize: "20px", textAlign: "center" }}
              placeholder={t("otp")}
              value={otp}
              onChange={(e) => {
                const val = e.target.value;
                if (val.length <= 6) setOtp(val);
                if (val.length === 6) {
                  Otp(val);
                }
              }}
            />
          )}
          <Button
            text={t("enter")}
            disabled={loading}
            onClick={() => Login()}
            className="font-weight-bold"
          />
        </div>
        <div style={{ position: "absolute", top: "90%", width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <select
              value={langCode}
              onChange={(e) => {
                // console.log(e.target.value);
                setLang(e.target.value);
                // this.props.changeLanguageCode(e.target.value);
              }}
            >
              <option value="he">עברית</option>
              <option value="en">English</option>
            </select>
          </div>
          <a
            style={{
              textAlign: "center",
              display: "block",
              marginTop: "10px",
              color: "white",
            }}
            href="https://hiteachtests.com/hopemap/tos.pdf"
            target="_blank"
          >
            {t("terms-of-service")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
