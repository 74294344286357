import React, { Component, useEffect } from "react";
import "./input.scss";

const Input = React.forwardRef((props, ref) => (
  <input
    id={props.id}
    autoFocus={props.autoFocus}
    style={props.style}
    type={props.type || "text"}
    onChange={props.onChange}
    value={props.value}
    placeholder={props.placeholder}
    {...props}
    className={props.className ? "input " + props.className : "input"}
    ref={ref}
  />
));

export default Input;
