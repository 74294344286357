import { combineReducers } from "redux";
import userReducer from "./user-reducer";
import mapReducer from "./map-reducer";
import categoryReducer from "./category-reducer";
import settingsReducer from "./settings-reducer";

// admin
import panelReducer from "./admin/panel-reducer";
import manageUsersReducer from "./admin/manage-users-reducer";

const rootReducer = combineReducers({
  userReducer,
  mapReducer,
  categoryReducer,
  // admin
  panelReducer,
  manageUsersReducer,
  settingsReducer,
});

export default rootReducer;
