import React, { Component } from "react";
import BottomBar from "../../../components/BottomBar/bottom-bar";
import "./choose-map-image.scss";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  changeMapBase64Image,
  changeImageIdFromGallery,
} from "actions/map-actions";
//
import Input from "../../../components/Input/input";
import ButtonSmall from "../../../components/ButtonSmall/button-small";
import TopStrip from "../../../components/TopStrip/top-strip";
import Api from "utils/api";
import Modal from "components/Modal/modal";
import { withTranslation } from "react-i18next";

class ChooseMapImage extends Component {
  state = { imageGallery: [] };
  changeProfileImage = (el) => {
    if (this.image.files.length == 1) {
      var img = new Image();
      img.onload = () => {
        const height = 200;
        const width = 200;
        let canvasHeight = 0;
        let canvasWidth = 0;
        let ratio = img.width / img.height;
        if (img.width > img.height) {
          canvasWidth = width;
          canvasHeight = width / ratio;
        } else {
          canvasWidth = height * ratio;
          canvasHeight = height;
        }
        const canvas = document.createElement("canvas");
        canvas.height = canvasHeight; //get original canvas height
        canvas.width = canvasWidth; // get original canvas width
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, canvasWidth, canvasHeight);
        this.props.changeMapBase64Image(canvas.toDataURL());

        // alert("the image is drawn");
      };
      img.onerror = (err) => {
        alert("this is not an image");
      };
      img.src = URL.createObjectURL(el.target.files[0]);
    } else {
      this.props.changeMapBase64Image(null);
    }
  };
  render() {
    const t = this.props.t;
    return (
      <div className="choose-map-image">
        <TopStrip />

        <div
          style={{
            padding: "10px 30px 15px 30px",
            marginTop: "20px",
            fontSize: "18px",
          }}
          className="text-disabled"
        >
          <div style={{ marginBottom: "10px" }}>
            {t("map-catagory")}:{" "}
            <strong>{t(`Catagory:${this.props.map.CategoryName}`)}</strong>
          </div>
          <span className="fadeIn">
            {t("main-goal")}: <strong>{this.props.map.MainGoal}</strong>
          </span>
        </div>
        <div className="bg-content" style={{ padding: "20px 30px" }}>
          <div className="text-dark-blue" style={{ fontSize: "20px" }}>
            {t("map-image")}
            <span
              style={{ fontSize: "16px", marginLeft: "5px" }}
              className="text-disabled"
            >
              ({t("Common:optional")})
            </span>
          </div>
          <p>{t("create-map-modal.choose-map-image.text")}</p>
          <br />
          <ButtonSmall
            text={t(
              "create-map-modal.choose-map-image.choose-from-image-gallery"
            )}
            onClick={this.getImageGallery}
          />
          <p style={{ marginLeft: "20px", color: "#999" }}>{t("Common:or")} </p>
          {this.state.imageGallery.length > 0 && (
            <Modal
              title={t("create-map-modal.choose-map-image.choose-from-gallery")}
              close={() => {
                this.setState({ imageGallery: [] });
                this.props.changeImageIdFromGallery("");
              }}
            >
              {/* Image Gallery */}
              <div className="img-gallery-container">
                {this.state.imageGallery.map((img) => {
                  return (
                    <img
                      style={
                        this.props.map.ImageIdFromGallery === img.Id
                          ? { border: "3px solid black" }
                          : {}
                      }
                      src={`Api/Data/MapImageFromGallery/${img.Id}`}
                      onClick={() =>
                        this.props.changeImageIdFromGallery(img.Id)
                      }
                    />
                  );
                })}
                <ButtonSmall
                  onClick={this.getImageGallery}
                  text={`${t("Common:load-more")}...`}
                  style={{
                    display: "inline-block",
                    margin: "15px 5px",
                  }}
                />
              </div>
              <br />
              <ButtonSmall
                disabled={this.props.map.ImageIdFromGallery === ""}
                primary
                text={`${t("Common:next")} >`}
                onClick={this.next}
              />
              {/* end Image Gallery */}
            </Modal>
          )}
          {this.props.map.Base64Image && (
            <div style={{ textAlign: "center" }}>
              <img src={this.props.map.Base64Image} />
            </div>
          )}
          <Input
            type="file"
            className="input"
            placeholder={`${t(
              "create-map-modal.choose-map-image.choose-or-take-image"
            )}...`}
            style={{ width: "100%" }}
            ref={(e) => (this.image = e)}
            onChange={this.changeProfileImage}
            accept="image/*"
          />
          <ButtonSmall
            style={{ marginTop: "10px" }}
            className="float-right"
            primary
            small
            text={
              this.props.map.Base64Image
                ? `${t("Common:next")} >`
                : `${t("Common:skip")} >`
            }
            onClick={this.next}
            disabled={this.props.map.MainGoal.length === 0}
          />
        </div>
        <BottomBar />
      </div>
    );
  }

  getImageGallery = () => {
    Api.get("/Data/MapImageFromGallery").then((res) => {
      if (res.data.Success) {
        const allImagesUrls = res.data.Payload;

        const currentAmountImgsInGallery = this.state.imageGallery.length;

        const amountNewImgsToLoad = 10;

        const newImageGallery = this.state.imageGallery;

        for (
          let i = currentAmountImgsInGallery;
          i < currentAmountImgsInGallery + amountNewImgsToLoad;
          i++
        ) {
          newImageGallery.push(allImagesUrls[i]);

          console.log(i);
        }

        this.setState({ imageGallery: newImageGallery });
      }
    });
    console.log(this.state.imageGallery);
  };

  componentDidMount = () => {
    if (!this.props.map.CategoryId) {
      this.props.history.push(
        this.props.match.params.edit !== undefined
          ? "/home"
          : "/create-map/questions"
      );
    }
  };

  next = () => {
    this.props.history.push(
      this.props.match.params.edit !== undefined
        ? "/create-map/enter-steps/edit"
        : "/create-map/enter-steps"
    );
  };
}

const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
    categories: state.categoryReducer.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeMapBase64Image,
      changeImageIdFromGallery,
    },
    dispatch
  );
};
export default withTranslation("Map")(
  connect(mapStateToProps, mapDispatchToProps)(ChooseMapImage)
);
