import Axios from "axios";
const AccessPoint = ""; //"http://192.168.3.90:4004";
const checkIEorEdge = function () {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  return isIE || isEdge;
};
const Api = Axios.create({
  headers: { "Cache-Control": "no-cache", Pragma: "no-cache" },
  baseURL: AccessPoint + "/Api/",
});
Api.interceptors.request.use(
  function (config) {
    if (!checkIEorEdge()) return config;

    let url = config.url;
    let parts = url.split("?");
    config.url =
      `${parts[0]}?ts=${new Date().getTime()}` +
      (parts.length > 1 ? `&${parts[1]}` : "");
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
export default Api;
