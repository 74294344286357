import React, { Component } from "react";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//
import BottomBar from "components/BottomBar/bottom-bar";
import "./settings-page.scss";
import TopStrip from "components/TopStrip/top-strip";
import { editReminderCycle } from "actions/user-actions";
import { changeLanguageCode } from "actions/settings-actions";
import Modal from "../../components/Modal/modal";
import { withTranslation } from "react-i18next";

class SettingsPage extends Component {
  state = {
    remindersModalOpen: false,
  };
  render() {
    const t = this.props.t;
    return (
      <div className="settings-page">
        <TopStrip smaller noImg title={t("settings")} />

        {/* Reminders Modal */}
        {this.state.remindersModalOpen && (
          <Modal
            title={t("reminders-modal.title")}
            // btnText="Start!"
            close={this.toggleRemindersModal}
          >
            <p>{t("reminders-modal.text1")}</p>
            <p>
              <strong style={{ textDecoration: "underline" }}>
                {t("reminders-modal.title")}
              </strong>{" "}
              {t("reminders-modal.text2")}
            </p>
            <p>{t("reminders-modal.text3")}</p>
          </Modal>
        )}
        <div style={{ padding: "20px" }}>
          <table
            style={{ borderCollapse: "separate", borderSpacing: "0 15px" }}
          >
            {/* Reminder settings */}
            <tr>
              <td>
                <input
                  checked={this.props.user.Settings.Notifications.Active}
                  type="checkbox"
                  style={{ margin: "0px" }}
                  onChange={(e) => {
                    this.props.editReminderCycle({
                      ...this.props.user.Settings.Notifications,
                      Active: e.target.checked,
                    });
                    console.log(e.target.checked);
                  }}
                />
                {/* <input type="checkbox" style={{ margin: "0px" }} /> */}
                <span
                  className="font-weight-bold"
                  style={{ margin: "0px 10px" }}
                >
                  {t("set-a-reminder-every")}
                </span>
              </td>
              <td>
                <select
                  value={this.props.user.Settings.Notifications.ReminderCycle}
                  onChange={(e) => {
                    this.props.editReminderCycle({
                      ...this.props.user.Settings.Notifications,
                      ReminderCycle: e.target.value,
                    });
                    console.log(e.target.checked);
                  }}
                >
                  <option value="7">{t("7-days")}</option>
                  <option value="14">{t("14-days")}</option>
                  <option value="30">{t("30-days")}</option>
                </select>
                <i
                  onClick={this.toggleRemindersModal}
                  style={{ color: "#999" }}
                  className="fas fa-question-circle"
                />
              </td>
            </tr>
            {/* Language settings */}
            <tr>
              <td>
                <i class="fas fa-globe"></i>
                {/* <input type="checkbox" style={{ margin: "0px" }} /> */}
                <span
                  className="font-weight-bold"
                  style={{ margin: "0px 10px" }}
                >
                  {t("change-language")}
                </span>
              </td>
              <td>
                <select
                  value={this.props.langCode}
                  onChange={(e) => {
                    this.setLang(e.target.value);
                    this.props.changeLanguageCode(e.target.value);
                  }}
                >
                  <option value="he">עברית</option>
                  <option value="en">English</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
        <BottomBar />
      </div>
    );
  }
  toggleRemindersModal = () => {
    this.setState({ remindersModalOpen: !this.state.remindersModalOpen });
  };

  setLang = (code) => {
    localStorage.setItem("homemap-lang", code);
    this.props.i18n.changeLanguage(code);
    this.props.changeLanguageCode(code);
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    langCode: state.settingsReducer.langCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editReminderCycle,
      changeLanguageCode,
    },
    dispatch
  );
};
export default withTranslation("Settings")(
  connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
);
