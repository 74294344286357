import React, { Component } from "react";
import "./enter-obstacles-and-overcomes.scss";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  changeMapStepObstacle,
  changeMapStepOvercome,
  createMap,
  editMap,
} from "actions/map-actions";
//
import BottomBar from "../../../components/BottomBar/bottom-bar";
import ButtonSmall from "../../../components/ButtonSmall/button-small";
import TextArea from "../../../components/TextArea/text-area";
import TopStrip from "../../../components/TopStrip/top-strip";
import { withTranslation } from "react-i18next";

class EnterSteps extends Component {
  state = {
    currentStep: 0,
  };

  render() {
    const t = this.props.t;
    return (
      <div className="enter-obstacles-and-overcomes">
        <TopStrip />

        <div
          style={{
            padding: "10px 30px 15px 30px",
            marginTop: "20px",
            fontSize: "18px",
          }}
          className="text-disabled"
        >
          <div style={{ marginBottom: "10px" }}>
            {t("map-catagory")}:{" "}
            <strong>{t(`Catagory:${this.props.map.CategoryName}`)}</strong>
          </div>
          <div style={{ marginBottom: "10px" }}>
            {t("main-goal")}: <strong>{this.props.map.MainGoal}</strong>
          </div>
          <div style={{ marginBottom: "10px" }}>{t("map-image")}</div>
          <span className="fadeIn">{t("steps")}</span>
          <br />
        </div>
        <div className="bg-content" style={{ padding: "20px 30px" }}>
          <div
            className="text-dark-blue"
            style={{ fontSize: "20px", marginBottom: "25px" }}
          >
            {t(
              "create-map-modal.enter-obstacles-and-overcomes.possible-obstacles"
            )}
          </div>
          <p style={{ marginBottom: "25px" }}>
            {t("create-map-modal.enter-obstacles-and-overcomes.text1")}
          </p>
          <p>{t("create-map-modal.enter-obstacles-and-overcomes.text2")}</p>
          <br />
          <div className="step-images-container">
            <div
              className={
                "step-image " + (this.state.currentStep === 0 && "active")
              }
            >
              <img
                onClick={() => {
                  this.changeCurrentStep(0);
                }}
                src={require("images/steps/green1.png")}
              />
            </div>
            <div
              className={
                "step-image " + (this.state.currentStep === 1 && "active")
              }
            >
              <img
                onClick={() => {
                  this.changeCurrentStep(1);
                }}
                src={require("images/steps/green2.png")}
              />
            </div>
            <div
              className={
                "step-image " + (this.state.currentStep === 2 && "active")
              }
            >
              <img
                onClick={() => {
                  this.changeCurrentStep(2);
                }}
                src={require("images/steps/green3.png")}
              />
            </div>
          </div>
          <div className="step-goal-text text-dark-blue">
            {this.props.map.Steps[this.state.currentStep].DueDate && (
              <span className="text-disabled" style={{ fontSize: "14px" }}>
                {new Date(
                  this.props.map.Steps[this.state.currentStep].DueDate
                ).toLocaleDateString()}
                <br />
              </span>
            )}
            {this.props.map.Steps[this.state.currentStep].Goal}
          </div>

          <br />

          <TextArea
            icon={require("images/steps/obstacle.png")}
            id="text-area"
            placeholder={t(
              "create-map-modal.enter-obstacles-and-overcomes.text3"
            )}
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => {
              this.props.changeMapStepObstacle(
                this.state.currentStep,
                e.target.value
              );
            }}
          >
            {this.props.map.Steps[this.state.currentStep].Obstacle}
          </TextArea>

          <TextArea
            icon={require("images/steps/overcome.png")}
            id="text-area"
            placeholder={t(
              "create-map-modal.enter-obstacles-and-overcomes.text4"
            )}
            style={{ width: "100%" }}
            onChange={(e) => {
              this.props.changeMapStepOvercome(
                this.state.currentStep,
                e.target.value
              );
            }}
          >
            {this.props.map.Steps[this.state.currentStep].Overcome}
          </TextArea>
          {/* <br />
          <br /> */}
          <ButtonSmall
            disabled={
              this.props.map.Steps[this.state.currentStep].Obstacle.length ===
                0 ||
              this.props.map.Steps[this.state.currentStep].Overcome.length === 0
            }
            style={{ marginTop: "10px" }}
            className="float-right"
            primary
            small
            text={
              this.state.currentStep === 2
                ? t("Common:finish")
                : `${t("Common:next")} >`
            }
            onClick={() => {
              // If last step
              if (this.state.currentStep === 2) {
                // If editing
                if (this.props.match.params.edit === undefined) {
                  this.props
                    .createMap({
                      ...this.props.map,
                      Questions: this.props.map.BeforeMapAnswers,
                    })
                    .then((newMapId) => {
                      this.props.history.push(
                        "/map/" + newMapId + "?show_map_modal"
                      );
                    });
                }
                // If not editing
                else {
                  this.props.editMap(this.props.map).then((newMapId) => {
                    this.props.history.push("/map/" + newMapId);
                  });
                }
              } else {
                this.changeCurrentStep(this.state.currentStep + 1);
              }
            }}
          />
        </div>
        <BottomBar />
      </div>
    );
  }

  changeCurrentStep = (stepNum) => {
    if (stepNum < 3) {
      document.getElementById("text-area").focus();

      // if you press the flag
      this.setState({
        currentStep: stepNum,
      });
    }
  };

  componentDidMount = () => {
    if (!this.props.map.CategoryId)
      this.props.history.push(
        this.props.match.params.edit !== undefined
          ? "/home"
          : "/create-map/questions"
      );
  };
}

const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
    categories: state.categoryReducer.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeMapStepObstacle,
      changeMapStepOvercome,
      createMap,
      editMap,
    },
    dispatch
  );
};
export default withTranslation("Map")(
  connect(mapStateToProps, mapDispatchToProps)(EnterSteps)
);
