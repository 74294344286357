import React, { Component } from "react";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getMap,
  changeMapPageImage,
  getMapQuestions,
  changeAfterMapAnswers,
  postAfterMapAnswers,
} from "actions/map-actions";
//
import BottomBar from "../../components/BottomBar/bottom-bar";
import CategoryShortcut from "../../components/CategoryShortcut/category-shortcut";
import "./map-page.scss";
import MapView from "./MapView/map-view";
import ListView from "./ListView/list-view";
import Api from "../../utils/api";
import ButtonSmall from "../../components/ButtonSmall/button-small";
import Modal from "components/Modal/modal";
import { withTranslation } from "react-i18next";

class MapPage extends Component {
  mapId = this.props.match.params.id;
  state = {
    mapView: true,
    imageRand: Date.now(),
    completedMapModal: false,
    afterMapQuestionsModal: false,
    mapModalOpen: true,
    mapViewModalOpen: false,
    listViewModalOpen: false,
    modalStep: 1,
  };
  changeProfileImage = (el) => {
    if (this.image.files.length == 1) {
      var img = new Image();
      img.onload = () => {
        const height = 200;
        const width = 200;
        let canvasHeight = 0;
        let canvasWidth = 0;
        let ratio = img.width / img.height;
        if (img.width > img.height) {
          canvasWidth = width;
          canvasHeight = width / ratio;
        } else {
          canvasWidth = height * ratio;
          canvasHeight = height;
        }
        const canvas = document.createElement("canvas");
        canvas.height = canvasHeight; //get original canvas height
        canvas.width = canvasWidth; // get original canvas width
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, canvasWidth, canvasHeight);
        this.setState({ img: canvas.toDataURL() });
        Api.post(`/User/Maps/${this.mapId}/Image`, {
          Base64Image: canvas.toDataURL(),
        }).then((res) => {
          this.setState({ imageRand: Date.now() });
          this.props.changeMapPageImage("true");
        });
        // alert("the image is drawn");
      };
      img.onerror = (err) => {
        alert("this is not an image");
      };
      img.src = URL.createObjectURL(el.target.files[0]);
    } else {
      return;
    }
  };
  render() {
    const t = this.props.t;
    const gotMap = this.props.map.CategoryName;

    const completedSteps = this.props.map.Steps.reduce((completed, step) => {
      if (step.IsAcomplished) {
        return ++completed;
      }
      return completed;
    }, 0);

    return (
      <div className="map-page">
        {/* List view Modal */}
        {this.state.listViewModalOpen && (
          <Modal
            title={t("list-view")}
            // btnText="Start!"
            close={this.toggleListViewModal}
          >
            <strong>{`"${t("list-view")}"`}</strong> {t("list-view-help")}
          </Modal>
        )}

        {/* List view Modal */}
        {this.state.mapViewModalOpen && (
          <Modal
            title={t("map-view")}
            // btnText="Start!"
            close={this.toggleMapViewModal}
          >
            <strong>{`"${t("map-view")}"`}</strong>
            {t("map-view-help1")}
            <br />
            {t("map-view-help2")}
          </Modal>
        )}

        {/* Map created modal */}
        {this.state.mapModalOpen &&
          window.location.href.indexOf("show_map_modal") !== -1 && (
            <Modal
              title={t("new-map-created-modal.title")}
              close={this.toggleMapModal}
            >
              {this.state.modalStep === 1 && (
                <>
                  <p>
                    <strong>{t("Common:excellent")}!</strong>{" "}
                    {t("new-map-created-modal.step1.text1")}
                  </p>
                  <p>{t("new-map-created-modal.step1.text2")}</p>
                </>
              )}
              {this.state.modalStep === 2 && (
                <>
                  <p>
                    <strong>{t("Common:remember")}:</strong>
                  </p>
                  <p
                    style={{
                      textAlign:
                        this.props.langCode === "he" ? "right" : "left",
                    }}
                  >
                    {t("new-map-created-modal.step2.text1")}
                    <br />
                    {t("new-map-created-modal.step2.text2")} <br />
                    {t("new-map-created-modal.step2.text3")} <br />
                    {t("new-map-created-modal.step2.text4")} <br />
                    {t("new-map-created-modal.step2.text5")}
                  </p>
                </>
              )}
              {this.state.modalStep === 3 && (
                <>
                  <p>
                    {t("new-map-created-modal.step3.text1")}{" "}
                    <img
                      style={{ margin: "0px 5px" }}
                      width={25}
                      src={require("../../images/steps/obstacle.png")}
                    ></img>{" "}
                    {t("new-map-created-modal.step3.text2")}
                  </p>
                  <p>
                    {t("new-map-created-modal.step3.text3")}
                    <br />
                    {t("new-map-created-modal.step3.text4")}
                    <br />
                    <strong>{t("new-map-created-modal.step3.text5")}</strong>
                  </p>
                </>
              )}
              {this.state.modalStep === 4 && (
                <>
                  <p>
                    <strong style={{ textDecoration: "underline" }}>
                      {t("Common:remember")}:
                    </strong>{" "}
                    {t("new-map-created-modal.step4.text1")}
                    <br />
                    {t("new-map-created-modal.step4.text2")}{" "}
                    <img
                      style={{ margin: "0px 5px" }}
                      width={25}
                      src={require("../../images/plus1.png")}
                    ></img>{" "}
                    {t("new-map-created-modal.step4.text3")}
                  </p>
                </>
              )}
              <br />
              {this.state.modalStep > 1 && (
                <ButtonSmall
                  text={`< ${t("Common:back")}`}
                  onClick={() =>
                    this.setState({ modalStep: this.state.modalStep - 1 })
                  }
                />
              )}
              <span style={{ margin: "0px 5px" }}></span>
              <ButtonSmall
                primary
                text={
                  this.state.modalStep === 4
                    ? `${t("Common:start")}!`
                    : `${t("Common:next")} >`
                }
                onClick={() => {
                  if (this.state.modalStep === 4) {
                    this.setState({ mapModalOpen: false });
                  } else {
                    this.setState({ modalStep: this.state.modalStep + 1 });
                  }
                }}
              />
            </Modal>
          )}

        <div className="map-gradient"></div>
        <div style={{ padding: "20px 10px 0px 10px" }}>
          {/* Top Buttons*/}
          <div className="toggle-edit-container">
            <span className="map-btn">
              <img
                src={require("images/map1.png")}
                onClick={() => this.props.history.push("/home")}
              />
            </span>

            <div className="map-view-toggle">
              <span
                onClick={() => {
                  this.setState({ mapView: true });
                }}
                className={
                  "map-view-btn " + (this.state.mapView ? "active" : "")
                }
              >
                <i
                  onClick={this.toggleMapViewModal}
                  style={{ color: "#999" }}
                  className="fas fa-question-circle"
                />
                <strong style={{ margin: "0px 7.5px" }}>{t("map-view")}</strong>
              </span>

              <span
                onClick={() => {
                  this.setState({ mapView: false });
                }}
                className={
                  "list-view-btn " + (this.state.mapView ? "" : "active")
                }
              >
                <strong style={{ margin: "0px 7.5px" }}>
                  {t("list-view")}
                </strong>
                <i
                  onClick={this.toggleListViewModal}
                  style={{ color: "#999" }}
                  className="fas fa-question-circle"
                />
              </span>
            </div>

            <span
              className="edit-map-btn"
              onClick={() =>
                this.props.history.push("/create-map/choose-category/edit")
              }
            >
              <img src={require("images/pen.png")} />
            </span>
          </div>
          <br />

          {/* Main Goal */}
          {!this.state.mapView && (
            <div className="main-goal-container">
              <div className="text">
                <span className="text-dark-blue font-weight-bold">
                  {t("main-goal")}
                </span>
                <br />
                <span>{this.props.map.MainGoal}</span>
              </div>
              <input
                onChange={(el) => this.changeProfileImage(el)}
                type="file"
                accept="image/*"
                ref={(el) => (this.image = el)}
                style={{ display: "none" }}
              />
              {this.props.map.MapImage ? (
                <CategoryShortcut
                  onClick={() => this.image.click()}
                  imgSrc={`Api/User/Maps/${this.mapId}/Image?${this.state.imageRand}`}
                  imgStyle={{
                    borderRadius: "50%",
                    border: "2px solid #fafafa",
                    boxShadow: "2px 2px rgba(0,0,0,0.1)",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <CategoryShortcut
                  onClick={() => this.image.click()}
                  imgSrc={
                    gotMap &&
                    require(`images/categories/${this.props.map.CategoryName}_${completedSteps}.png`)
                  }
                />
              )}
            </div>
          )}
        </div>

        {this.state.mapView ? (
          <MapView
            completedSteps={completedSteps}
            history={this.props.history}
            showAfterMapQuestionsModal={this.showAfterMapQuestionsModal}
          />
        ) : (
          <ListView />
        )}

        <BottomBar />

        {/* After map questions */}
        {this.state.afterMapQuestionsModal && (
          <Modal
            title={t("after-map-questions-modal.title")}
            close={this.closeAfterMapQuestionsModal}
          >
            {/* start */}
            {this.props.map.Questions &&
              this.props.map.Questions.AfterMap.map((question, qIndex) => {
                return (
                  <div
                    key={question.Id}
                    className="question-container fadeIn"
                    style={{
                      textAlign:
                        this.props.langCode === "he" ? "right" : "left",
                    }}
                  >
                    <div
                      className="text-dark-blue"
                      style={{ fontSize: "18px", marginBottom: "15px" }}
                    >
                      {t(`after-map-questions-modal.q${qIndex}`)}
                    </div>

                    <div className="answers">
                      {question.PossibleAnswers.map((possibleAnswer, index) => {
                        return (
                          <React.Fragment key={possibleAnswer.Id}>
                            <input
                              onClick={() =>
                                this.onSelectAnswer(
                                  qIndex,
                                  question.Id,
                                  possibleAnswer.Id
                                )
                              }
                              className="answer"
                              type="radio"
                              value={possibleAnswer.Answer}
                              name={`question${qIndex}`}
                            />
                            <label htmlFor="male">
                              {t(`create-map-modal.questions.a${index}`)}
                            </label>
                            <br />
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div style={{ padding: "15px" }}></div>
                  </div>
                );
              })}

            <ButtonSmall
              primary
              text={`${t("Common:finish")}!`}
              disabled={
                this.props.map.AfterMapAnswers[0].SelectedAnswerId === "" ||
                this.props.map.AfterMapAnswers[1].SelectedAnswerId === ""
              }
              onClick={() => {
                this.closeAfterMapQuestionsModal();

                // Post to server
                this.props
                  .postAfterMapAnswers({
                    MapId: this.mapId,
                    Questions: this.props.map.AfterMapAnswers,
                  })
                  .then(() => {
                    setTimeout(this.showCompletedMapModal, 1000);
                    // this.showCompletedMapModal();
                  });
              }}
            />
          </Modal>
        )}
        {/* end */}

        {/* Complete map modal */}
        {this.state.completedMapModal && (
          <Modal
            title={`${t("Common:congratulations")}!`}
            close={this.closeCompletedMapModal}
          >
            {t("complete-map-modal.text1")}
            <br />
            <br />
            {t("complete-map-modal.text2")}
            <br />
            <br />
            <ButtonSmall
              primary
              text={t("Journey:go-to-my-journey")}
              onClick={() => {
                this.props.history.push("/home");
              }}
            />
          </Modal>
        )}
      </div>
    );
  }

  toggleMapViewModal = () => {
    this.setState({ mapViewModalOpen: !this.state.mapViewModalOpen });
  };

  toggleListViewModal = () => {
    this.setState({ listViewModalOpen: !this.state.listViewModalOpen });
  };

  componentDidMount = () => {
    this.props.getMap(this.mapId).then(() => {
      if (this.props.map.FinishedAllSteps && !this.props.map.IsArchived) {
        this.showAfterMapQuestionsModal();
      }
    });
  };

  toggleMapModal = () => {
    this.setState({ mapModalOpen: !this.state.mapModalOpen });
  };

  showAfterMapQuestionsModal = () => {
    this.setState({ afterMapQuestionsModal: true });
  };

  closeAfterMapQuestionsModal = () => {
    this.setState({ afterMapQuestionsModal: false });
  };

  showCompletedMapModal = () => {
    this.setState({ completedMapModal: true });
  };

  closeCompletedMapModal = () => {
    this.setState({ completedMapModal: false });
  };

  onSelectAnswer = (questionIndex, questionId, selectedAnswerId) => {
    this.props.changeAfterMapAnswers({
      questionIndex,
      questionId,
      selectedAnswerId,
    });
  };
}
const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
    langCode: state.settingsReducer.langCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMap,
      changeMapPageImage,
      getMapQuestions,
      changeAfterMapAnswers,
      postAfterMapAnswers,
    },
    dispatch
  );
};
export default withTranslation("Map")(
  connect(mapStateToProps, mapDispatchToProps)(MapPage)
);
