import Api from "utils/api";

export const getUsers = () => {
  return (dispatch) => {
    return Api.get("/Admin/Users/9999/1")
      .then((res) => {
        if (res.data.Success) {
          dispatch({
            type: "GET_USERS",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

// Related to reate User:

export const changeUserCreating = (newUser) => {
  return {
    type: "CHANGE_USER_CREATING",
    payload: newUser,
  };
};

export const resetUserCreating = () => {
  return {
    type: "RESET_USER_CREATING",
  };
};

export const createUser = (user) => {
  return (dispatch) => {
    return Api.post("/Admin/Users/Create", { Users: [user] })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const editUser = (user) => {
  console.log(user);
  return (dispatch) => {
    return Api.put(`/Admin/Users/${user.Id}`, user)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const deleteUser = (user) => {
  console.log(user);
  return (dispatch) => {
    return Api.put(`/Admin/Users/${user.Id}`, { ...user, IsActive: false })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  };
};
