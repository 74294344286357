const initialState = {
  user: {},
  isLoggedIn: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_USER":
      return { ...state, user: action.payload };
    case "SET_LOGIN":
      return { ...state, isLoggedIn: action.payload };
    case "EDIT_REMINDER_CYCLE":
      return {
        ...state,
        user: {
          ...state.user,
          Settings: { ...state.user.Settings, Notifications: action.payload },
        },
      };
    default:
      return state;
  }
}
