import Api from "utils/api";

export const resetMap = () => {
  return {
    type: "RESET_MAP",
  };
};

export const getMaps = () => {
  return (dispatch) => {
    return Api.get("User/Maps")
      .then((res) => {
        if (res.data.Success) {
          dispatch({
            type: "GET_MAPS",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const getArchivedMaps = () => {
  return (dispatch) => {
    return Api.get("User/Maps/archive")
      .then((res) => {
        if (res.data.Success) {
          dispatch({
            type: "GET_ARCHIVED_MAPS",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const getMap = (id) => {
  return (dispatch) => {
    return Api.get(`User/Maps/${id}`)
      .then((res) => {
        if (res.data.Success) {
          dispatch({
            type: "GET_MAP",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const getMapQuestions = () => {
  return (dispatch) => {
    return Api.get("/Data/Questions")
      .then((res) => {
        if (res.data.Success) {
          dispatch({
            type: "GET_MAP_QUESTIONS",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const changeBeforeMapAnswers = (newAnswers) => {
  return {
    type: "CHANGE_BEFORE_MAP_ANSWERS",
    payload: newAnswers,
  };
};

export const changeAfterMapAnswers = (newAnswers) => {
  return {
    type: "CHANGE_AFTER_MAP_ANSWERS",
    payload: newAnswers,
  };
};

export const postAfterMapAnswers = (data) => {
  return (dispatch) => {
    return Api.post("User/Maps/FinishMap", data)
      .then((res) => {
        if (res.data.Success) {
          return res.data.Payload;
          // dispatch({
          //   type: "CREATE_MAP",
          //   payload: res.data.Payload,
          // });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const changeMapPageImage = (set) => {
  return {
    type: "CHANGE_MAP_PAGE_IMAGE",
    payload: set,
  };
};

export const changeMapCategory = (category) => {
  return {
    type: "CHANGE_MAP_CATEGORY",
    payload: category,
  };
};

export const changeMapMainGoal = (text) => {
  return {
    type: "CHANGE_MAP_MAIN_GOAL",
    payload: text,
  };
};

export const changeMapWord = (text) => {
  return {
    type: "CHANGE_MAP_WORD",
    payload: text,
  };
};

export const changeMapBase64Image = (base64) => {
  return {
    type: "CHANGE_MAP_BASE64_IMAGE",
    payload: base64,
  };
};

export const changeImageIdFromGallery = (imgId) => {
  return {
    type: "CHANGE_IMAGE_ID_FROM_GALLERY",
    payload: imgId,
  };
};

export const changeMapStepGoal = (index, text) => {
  return {
    type: "CHANGE_MAP_STEP_GOAL",
    payload: { index, text },
  };
};

export const changeMapStepDueDate = (index, date) => {
  return {
    type: "CHANGE_MAP_STEP_DUE_DATE",
    payload: { index, date },
  };
};

export const changeMapStepObstacle = (index, text) => {
  return {
    type: "CHANGE_MAP_STEP_OBSTACLE",
    payload: { index, text },
  };
};

export const changeMapStepOvercome = (index, text) => {
  return {
    type: "CHANGE_MAP_STEP_OVERCOME",
    payload: { index, text },
  };
};

export const createMap = (map) => {
  return (dispatch) => {
    return Api.post("User/Maps", map)
      .then((res) => {
        if (res.data.Success) {
          return res.data.Payload;
          // dispatch({
          //   type: "CREATE_MAP",
          //   payload: res.data.Payload,
          // });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const editMap = (map) => {
  return (dispatch) => {
    return Api.put("User/Maps", map)
      .then((res) => {
        if (res.data.Success) {
          return res.data.Payload;
          // dispatch({
          //   type: "CREATE_MAP",
          //   payload: res.data.Payload,
          // });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const updateStep = (stepData) => {
  return (dispatch) => {
    return Api.post("User/Maps/UpdateStep", stepData)
      .then((res) => {
        if (res.data.Success) {
          dispatch({
            type: "UPDATE_STEP",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};
