import React, { Component } from "react";
import "./manage-users.scss";
import TopStrip from "components/TopStrip/top-strip";
import BottomBar from "components/BottomBar/bottom-bar";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUsers,
  editUser,
  deleteUser,
} from "actions/admin/manage-users-actions";
//
import ButtonSmall from "../../../components/ButtonSmall/button-small";
import Modal from "components/Modal/modal";
import Input from "components/Input/input";
import PhoneInput from "react-phone-input-2";
import { withTranslation } from "react-i18next";

class ManageUsers extends Component {
  state = {
    userToDelete: null,
    userToEdit: null,
    isEditUserBtnDisabled: false,
    userEditedSuccessfullyModalOpen: false,
    userDeletedSuccessfullyModalOpen: false,
  };
  render() {
    const t = this.props.t;
    return (
      <div className="manage-users-page">
        <TopStrip smaller noImg title={t("admin.admin-panel")} />
        <div
          className="text-dark-blue text-center font-weight-bold"
          style={{ fontSize: "22px", margin: "-30px 0px 25px" }}
        >
          {t("admin.manage-users.manage-users")}
        </div>
        {this.props.users && (
          <React.Fragment>
            <div className="table-container">
              <table className="fadeIn">
                <tr>
                  <th>{t("admin.manage-users.full-name")}</th>
                  <th>{t("admin.manage-users.phone")}</th>
                  <th>{t("admin.manage-users.created-at")}</th>
                  <th>{t("Map:active-maps")}</th>
                </tr>
                {this.props.users
                  .filter((user) => user.IsActive)
                  .map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {user.FullName}
                          <i
                            className="fa fa-pen"
                            onClick={() => this.toggleEditUserModal(user)}
                          />
                          <i
                            className="fa fa-trash-alt"
                            onClick={() => this.toggleDeleteUserModal(user)}
                          />
                        </td>

                        <td>{`+${user.Phone.CountryCode} ${user.Phone.Number}`}</td>
                        <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                        <td>{user.ActiveMapsAmount}</td>
                      </tr>
                    );
                  })}
              </table>
            </div>

            <div className="text-center">
              <ButtonSmall
                text={t("admin.manage-users.back-to-top")}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          </React.Fragment>
        )}

        <br />

        {/* Delete User Modal */}
        {this.state.userToDelete && (
          <Modal
            title={t("admin.manage-users.delete-user-modal.delete-user")}
            close={() => {
              this.setState({ userToDelete: null });
            }}
          >
            {`${t("admin.manage-users.delete-user-modal.text")}
            "${this.state.userToDelete.FirstName}
               ${this.state.userToDelete.LastName}"?`}

            <br />
            <br />
            <ButtonSmall
              primary
              text={t("Common:confirm")}
              onClick={() => {
                this.props.deleteUser(this.state.userToDelete).then((res) => {
                  if (res.Success) {
                    this.toggleUserDeletedSuccessfullyModal();
                    this.setState({ userToDelete: null });
                    this.props.getUsers();
                  }
                });
              }}
            />
          </Modal>
        )}
        {/* End delete user modal */}

        {/* Edit user modal */}
        {this.state.userToEdit && (
          <Modal
            modalStyle={{ overflow: "visible" }}
            close={() => this.setState({ userToEdit: null })}
            title={t("admin.manage-users.edit-user")}
          >
            <Input
              className="edit-user-input"
              placeholder={`${t("admin.create-new-user-modal.first-name")}...`}
              value={this.state.userToEdit.FirstName}
              onChange={(e) => {
                this.changeUserToEdit({
                  ...this.state.userToEdit,
                  FirstName: e.target.value,
                });
              }}
            />
            <Input
              className="edit-user-input"
              placeholder={`${t("admin.create-new-user-modal.last-name")}...`}
              value={this.state.userToEdit.LastName}
              onChange={(e) => {
                this.changeUserToEdit({
                  ...this.state.userToEdit,
                  LastName: e.target.value,
                });
              }}
            />
            <PhoneInput
              style={{ margin: "12.5px auto 0px auto" }}
              disabled={false}
              containerClass="phone"
              inputClass="phone-input"
              buttonClass="phone-button"
              dropdownClass="phone-select"
              enableSearch={true}
              countryCodeEditable={false}
              country={"us"}
              value={
                this.state.userToEdit.Phone.CountryCode +
                this.state.userToEdit.Phone.Number
              }
              onChange={(phone, country) => {
                console.log("changing user to edit");
                this.changeUserToEdit({
                  ...this.state.userToEdit,
                  Phone: {
                    Number: phone.slice(country.dialCode.length),
                    CountryCode: country.dialCode,
                  },
                });
              }}
            />

            <br />
            <ButtonSmall
              disabled={this.isEditUserBtnDisabled()}
              primary
              text={t("Common:confirm")}
              className="edit-user-btn"
              onClick={() => {
                this.setState({ editUserBtnDisabled: true });
                this.props.editUser(this.state.userToEdit).then((res) => {
                  if (res.Success) {
                    this.setState({ editUserBtnDisabled: false });
                    this.toggleUserEditedSuccessfullyModal();
                    this.setState({ userToEdit: null });
                    this.props.getUsers();
                  }
                });
              }}
            />
          </Modal>
        )}

        {this.state.userDeletedSuccessfullyModalOpen && (
          <Modal
            title={t(
              "admin.manage-users.user-deleted-successfully-modal.title"
            )}
            close={this.toggleUserDeletedSuccessfullyModal}
          >
            {t("admin.manage-users.user-deleted-successfully-modal.text")}
          </Modal>
        )}

        {this.state.userEditedSuccessfullyModalOpen && (
          <Modal
            title={t("admin.manage-users.user-edited-successfully-modal.title")}
            close={this.toggleUserEditedSuccessfullyModal}
          >
            {t("admin.manage-users.user-edited-successfully-modal.text")}
          </Modal>
        )}

        <BottomBar />
      </div>
    );
  }

  isEditUserBtnDisabled = () => {
    if (this.state.isEditUserBtnDisabled) return true;

    if (
      this.state.userToEdit.FirstName === "" ||
      this.state.userToEdit.LastName === "" ||
      this.state.userToEdit.Phone.Number.length === 0
    )
      return true;

    return false;
  };

  changeUserToEdit = (user) => {
    console.log("awdawd");
    this.setState({ userToEdit: user });
  };

  componentDidMount = () => {
    this.props.getUsers();
  };

  toggleDeleteUserModal = (user) => {
    this.setState({ userToDelete: user });
  };

  toggleEditUserModal = (user) => {
    this.setState({ userToEdit: user });
    console.log(user);
  };

  toggleUserEditedSuccessfullyModal = () => {
    this.setState({
      userEditedSuccessfullyModalOpen: !this.state
        .userEditedSuccessfullyModalOpen,
    });
  };

  toggleUserDeletedSuccessfullyModal = () => {
    this.setState({
      userDeletedSuccessfullyModalOpen: !this.state
        .userDeletedSuccessfullyModalOpen,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    users: state.manageUsersReducer.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUsers,
      editUser,
      deleteUser,
    },
    dispatch
  );
};
export default withTranslation("HomePage")(
  connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
);
