const initialState = {
  adminPanelStats: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_STATS":
      return { ...state, adminPanelStats: action.payload };

    default:
      return state;
  }
}
