import React, { Component } from "react";
import "./map-shortcut.scss";

class MapShortcut extends Component {
  state = {};
  render() {
    return (
      <div
        onClick={() => {
          this.props.history.push(`/map/${this.props.Id}`);
        }}
        className="map-shortcut fadeIn"
      >
        <img className="image" src={require("images/map-img.png")} />
        <div className="text">{this.props.text}</div>
      </div>
    );
  }
}

export default MapShortcut;
