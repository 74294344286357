import React, { Component } from "react";
import "./top-strip.scss";
// Redux
import { connect } from "react-redux";
//
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

class TopStrip extends Component {
  state = {};
  render() {
    const t = this.props.t;
    return (
      <div className="top-strip-container">
        <div
          className={
            this.props.match.params.edit !== undefined || this.props.smaller
              ? "top-strip editing"
              : "top-strip"
          }
        >
          {/* Title */}
          <div className="strip-title">
            {this.props.title ||
              (this.props.match.params.edit !== undefined
                ? t("edit-map")
                : t("create-a-new-map"))}
          </div>

          {/* Image */}
          {!this.props.noImg &&
            this.props.map.CategoryId &&
            this.props.match.params.edit === undefined && (
              <img
                className="profile-img"
                src={require(`images/categories/${
                  this.props.map.CategoryName
                }_${3}.png`)}
              />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
  };
};

export default withTranslation("Map")(
  withRouter(connect(mapStateToProps, null)(TopStrip))
);
