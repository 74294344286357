import React, { Component } from "react";
import ButtonSmall from "../ButtonSmall/button-small";
import "./modal.scss";
import { withTranslation } from "react-i18next";

class Modal extends Component {
  state = {};
  render() {
    const t = this.props.t;
    return localStorage.getItem(this.props.localStorageString) === null ||
      localStorage.getItem(this.props.localStorageString) === "true" ? (
      <div className="modal-bg fadeIn" style={this.props.style}>
        <div className="modal fadeInDown" style={this.props.modalStyle}>
          {!this.props.noCloseBtn && (
            <div className="close-btn" onClick={this.props.close}>
              &times;
            </div>
          )}
          <div className="modal-content">
            <div className="modal-title">{this.props.title}</div>
            <br />
            {this.props.children}
            {(this.props.onSubmit || this.props.btnText) && (
              <ButtonSmall
                primary
                text={this.props.btnText || t("Common:close")}
                onClick={this.props.onSubmit || this.props.close}
              />
            )}
            <br />
            <br />
            {this.props.localStorageString && (
              <localStorage>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem(
                      this.props.localStorageString,
                      !e.target.checked.toString()
                      // If checked = true , showModal = false (because true means "hide this modal")
                    )
                  }
                />

                <span>{t("dont-show-again")}</span>
              </localStorage>
            )}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withTranslation("Common")(Modal);
