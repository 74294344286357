import React, { Component } from "react";
import "./category-shortcut.scss";

class CategoryShortcut extends Component {
  state = {};
  render() {
    return (
      <div
        className={"category-shortcut fadeIn " + this.props.className}
        onClick={this.props.onClick}
      >
        <img style={this.props.imgStyle} src={this.props.imgSrc} />
        <br />
        <span>{this.props.text}</span>
      </div>
    );
  }
}

export default CategoryShortcut;
