import React, { Component } from "react";
import BottomBar from "../../../components/BottomBar/bottom-bar";
import "./enter-main-goal.scss";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeMapMainGoal, changeMapWord } from "actions/map-actions";
//
import Input from "../../../components/Input/input";
import ButtonSmall from "../../../components/ButtonSmall/button-small";
import TopStrip from "../../../components/TopStrip/top-strip";
import _ from "lodash";
import Api from "utils/api";
import { withTranslation } from "react-i18next";

class EnterMainGoal extends Component {
  state = {
    autocompleteOptions: [],
  };

  render() {
    const t = this.props.t;
    return (
      <div className="enter-main-goal">
        <TopStrip />

        <div
          style={{
            padding: "10px 30px 15px 30px",
            marginTop: "20px",
            fontSize: "18px",
          }}
          className="text-disabled fadeIn"
        >
          {t("map-catagory")}:{" "}
          <strong>{t(`Catagory:${this.props.map.CategoryName}`)}</strong>
        </div>
        <div className="bg-content" style={{ padding: "20px 30px" }}>
          <div className="text-dark-blue" style={{ fontSize: "20px" }}>
            {t("main-goal")}
          </div>
          <p>{t("create-map-modal.enter-main-goal.text1")}</p>
          {/* <br /> */}
          <Input
            className="input"
            value={this.props.map.MainGoal}
            placeholder={t("create-map-modal.enter-main-goal.i-want-to")}
            style={{ width: "100%" }}
            onChange={(e) => {
              this.getAutocompleteOptions(e.target.value);
              this.props.changeMapMainGoal(e.target.value);
            }}
          />
          {/* Main goal autocomplete */}
          <div className="autocomplete-container">
            {this.state.autocompleteOptions.length > 0 && (
              <div style={{ color: "#999", margin: "10px 20px" }}>
                {t("Common:suggestions")}:
              </div>
            )}
            {this.state.autocompleteOptions.map((option, index) => {
              return (
                <div
                  key={index}
                  className="autocomplete-option"
                  onClick={() => this.props.changeMapMainGoal(option.Name)}
                >
                  {option.Name}
                </div>
              );
            })}
          </div>
          <br />
          <p>{t("create-map-modal.enter-main-goal.text2")}</p>
          {/* <br /> */}
          <Input
            className="input"
            value={this.props.map.Word}
            placeholder={t(
              "create-map-modal.enter-main-goal.my-strengthen-word-is"
            )}
            style={{ width: "100%" }}
            onChange={(e) => {
              this.props.changeMapWord(e.target.value);
            }}
          />
          <ButtonSmall
            style={{ marginTop: "10px" }}
            className="float-right"
            primary
            small
            text={`${t("Common:next")} >`}
            onClick={this.next}
            disabled={this.props.map.MainGoal.length === 0}
          />
        </div>
        <BottomBar />
      </div>
    );
  }

  getAutocompleteOptions = _.debounce((string) => {
    if (string.length > 0) {
      Api.get(`/Data/MainGoalSearch?search=${string}`).then((res) => {
        if (res.data.Success) {
          this.setState({ autocompleteOptions: res.data.Payload });
        } else {
          console.log("Error");
        }
      });
    } else {
      this.setState((prevState) => {
        return { ...prevState, autocompleteOptions: [] };
      });
    }
  }, 500);

  componentDidMount = () => {
    if (!this.props.map.CategoryId)
      this.props.history.push(
        this.props.match.params.edit !== undefined
          ? "/home"
          : "/create-map/questions"
      );
  };

  next = () => {
    this.props.history.push(
      this.props.match.params.edit !== undefined
        ? "/create-map/choose-map-image/edit"
        : "/create-map/choose-map-image"
    );
  };
}

const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
    categories: state.categoryReducer.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeMapMainGoal,
      changeMapWord,
    },
    dispatch
  );
};
export default withTranslation("Map")(
  connect(mapStateToProps, mapDispatchToProps)(EnterMainGoal)
);
