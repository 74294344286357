import i18n from "i18next";
import { initReactI18next as reactI18nextModule } from "react-i18next";
import * as en from "./locales/en";
import * as he from "./locales/he";

const resources = {
  en,
  he,
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  //.use(intervalPlural)
  .init({
    lng: "he",
    debug: false,
    resources,
    fallbackLng: "en",
    defaultNS: "Common",
    fallbackNS: "Common",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
