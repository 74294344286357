import React, { Component } from "react";
import BottomBar from "../../../components/BottomBar/bottom-bar";
import CategoryShortcut from "../../../components/CategoryShortcut/category-shortcut";
import "./choose-map-category.scss";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeMapCategory, resetMap } from "actions/map-actions";
import { getCategories } from "actions/category-actions";
import TopStrip from "../../../components/TopStrip/top-strip";
import Modal from "../../../components/Modal/modal";
import { withTranslation } from "react-i18next";

class ChooseMapCategory extends Component {
  state = {
    createMapModalOpen: true,
  };
  render() {
    const t = this.props.t;
    return (
      <div className="choose-map-category">
        <TopStrip noImg />

        {/* Create map Modal */}
        {this.state.createMapModalOpen && (
          <Modal
            localStorageString="show_create_map_modal"
            title={`${t("Common:welcome")}, ${this.props.user.firstName}!`}
            btnText={`${t("Common:start")}!`}
            close={this.toggleCreateMapModal}
          >
            <p>{t("create-map-modal.choose-category.text1")}</p>

            <p>{t("create-map-modal.choose-category.text2")}</p>
            {/* <p>
              <strong>
                - Choose an area of your life which is important to you where
                you would like to improve or have greater sense of satisfaction.
              </strong>
            </p>
            <p>
              <strong>- Set a goal.</strong> The goal should have meaning in
              your life. It should be able to be achieved (even if it's a
              stretch).
            </p>
            <p>
              <strong>- Choose 3 steps to get closer to your goal.</strong>{" "}
              Clear, practical- small steps are best!
            </p>
            <p>GREAT! We're almost there....</p>
            <p>
              Now, think of <strong>obstacles</strong> that may creep up along
              the way that may prevent you from achieving these steps. Think
              about ways that you can <strong>overcome those obstacles</strong>
            </p> */}
            <p>
              <strong>{t("create-map-modal.choose-category.text3")}</strong>
            </p>
            <p>
              <strong>{t("create-map-modal.choose-category.text4")}</strong>
            </p>
            <p style={{ textAlign: "center" }}>
              <strong style={{ textDecoration: "underline" }}>
                {t("Common:remember")}:
              </strong>{" "}
              {t("create-map-modal.choose-category.text5")}{" "}
              <img
                style={{ margin: "0px 5px" }}
                width={25}
                src={require("../../../images/plus1.png")}
              ></img>{" "}
              {t("create-map-modal.choose-category.text6")}
            </p>
          </Modal>
        )}

        <div className="bg-content" style={{ padding: "30px" }}>
          <div className="text-dark-blue" style={{ fontSize: "20px" }}>
            {t("map-catagory")}
          </div>
          <p>{t("create-map-modal.choose-category.map-text1")}</p>
          <p>{t("create-map-modal.choose-category.map-text2")}</p>

          <div className="categories-container">
            {this.props.categories &&
              this.props.categories.map((category) => {
                return (
                  <CategoryShortcut
                    key={category.Id}
                    onClick={() => this.onSelectCategory(category)}
                    imgSrc={require(`images/categories/${
                      category.Name
                    }_${3}.png`)}
                    text={t(`Catagory:${category.Name}`)}
                  />
                );
              })}
          </div>
        </div>
        <BottomBar />
      </div>
    );
  }

  componentDidMount = () => {
    // If not editing && no questions
    if (
      this.props.match.params.edit === undefined &&
      !this.props.map.Questions
    ) {
      this.props.history.push("/create-map/questions");
    }
    // if (!this.props.map.CategoryId)
    //   this.props.history.push(
    //     this.props.match.params.edit !== undefined
    //       ? "/home"
    //       : "/create-map/questions"
    //   );

    if (this.props.categories === null) {
      this.props.getCategories();
    }
  };

  toggleCreateMapModal = () => {
    this.props.user &&
      this.setState({ createMapModalOpen: !this.state.createMapModalOpen });
  };

  onSelectCategory = (category) => {
    this.props.changeMapCategory(category);
    this.props.history.push(
      this.props.match.params.edit !== undefined
        ? "/create-map/enter-main-goal/edit"
        : "/create-map/enter-main-goal"
    );
  };
}

const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
    categories: state.categoryReducer.categories,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeMapCategory,
      getCategories,
      // resetMap,
    },
    dispatch
  );
};
export default withTranslation("Map")(
  connect(mapStateToProps, mapDispatchToProps)(ChooseMapCategory)
);
