import Api from "utils/api";

export const getCategories = () => {
  return (dispatch) => {
    return Api.get("User/Categories")
      .then((res) => {
        if (res.data.Success) {
          dispatch({
            type: "GET_CATEGORIES",
            payload: res.data.Payload,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};
