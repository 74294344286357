import React, { Component, useEffect } from "react";
import "./text-area.scss";

class TextArea extends Component {
  state = {};
  render() {
    return (
      <div>
        {this.props.icon && (
          <img className="text-area-img" src={this.props.icon} />
        )}

        <textarea
          id={this.props.id}
          value={this.props.children}
          autoFocus={this.props.autoFocus}
          style={this.props.style}
          className={
            this.props.className
              ? "text-area " + this.props.className
              : "text-area"
          }
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          {...this.props}
        />
      </div>
    );
  }
}

export default TextArea;
