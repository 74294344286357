import React, { Component } from "react";
import "./home-page.scss";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUser } from "actions/user-actions";
import { getCategories } from "actions/category-actions";
import { getMaps, getArchivedMaps, resetMap } from "actions/map-actions";
//
import MapShortcut from "../../components/MapShortcut/map-shortcut";
import BottomBar from "../../components/BottomBar/bottom-bar";
import CategoryShortcut from "../../components/CategoryShortcut/category-shortcut";
import { Link } from "react-router-dom";
import ButtonSmall from "../../components/ButtonSmall/button-small";
import Api from "../../utils/api";
import Modal from "../../components/Modal/modal";
import { withTranslation } from "react-i18next";

class HomePage extends Component {
  state = {
    img: null,
    imageRand: Date.now(),
    showArchivedMaps: false,
    myJourneyModalOpen: true,
  };
  changeProfileImage = (el) => {
    if (this.file.files.length == 1) {
      var img = new Image();
      img.onload = () => {
        const height = 200;
        const width = 200;
        let canvasHeight = 0;
        let canvasWidth = 0;
        let ratio = img.width / img.height;
        if (img.width > img.height) {
          canvasWidth = width;
          canvasHeight = width / ratio;
        } else {
          canvasWidth = height * ratio;
          canvasHeight = height;
        }
        const canvas = document.createElement("canvas");
        canvas.height = canvasHeight; //get original canvas height
        canvas.width = canvasWidth; // get original canvas width
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, canvasWidth, canvasHeight);
        this.setState({ img: canvas.toDataURL() });
        Api.post("/User/Profile/UpdateImage", {
          base64image: canvas.toDataURL(),
        }).then((res) => {
          this.setState({ imageRand: Date.now() });
        });
        // alert("the image is drawn");
      };
      img.onerror = (err) => {
        alert("this is not an image");
      };
      img.src = URL.createObjectURL(el.target.files[0]);
    } else {
      return;
    }
  };
  render() {
    //require("images/profile-img-placeholder.jpg")}
    const t = this.props.t;

    return (
      <div className="home-page">
        {/* My journey Modal */}
        {this.state.myJourneyModalOpen && (
          <Modal
            localStorageString="show_my_journey_modal"
            title={t("Journey:my-journey")}
            btnText={`${t("Common:start")}!`}
            close={this.toggleMyJourneyModal}
          >
            <p>
              <strong>{t("my-journey-modal.my-journey")}</strong>{" "}
              {this.props.t("my-journey-modal.text1")}
              <br /> {t("my-journey-modal.text2")}
            </p>
            <p>{t("my-journey-modal.text3")}</p>
            <p>
              {t("my-journey-modal.text4")}{" "}
              <img
                style={{ margin: "0px 5px" }}
                width={25}
                src={require("../../images/plus1.png")}
              ></img>{" "}
              {t("my-journey-modal.text5")}
            </p>
          </Modal>
        )}

        {/* Welcome Modal */}
        {/* {this.state.myJourneyModalOpen && (
          <Modal
            title={`Welcome, ${this.props.user.firstName}!`}
            btnText="Start!"
            close={this.toggleMyJourneyModal}
          >
            Welcome to your Hopetimize journey – your personalized tool to
            optimize your health choices and the quality of day to day living.
            <br />
            <br />
          </Modal>
        )} */}

        <div className="top-strip">
          <img
            className="profile-img fadeIn"
            src={"Api/User/Profile/Image?" + this.state.imageRand}
            style={{ cursor: "pointer" }}
            onClick={() => this.file.click()}
          />
          <input
            onChange={(el) => this.changeProfileImage(el)}
            type="file"
            accept="image/*"
            ref={(el) => (this.file = el)}
            style={{ display: "none" }}
          />
        </div>
        <div className="title text-blue font-weight-bold text-center">
          {t("Journey:my-journey")}
        </div>

        <div className="maps-container">
          <div className="titles">
            <div
              onClick={() => {
                this.setState({ showArchivedMaps: false });
              }}
              className={
                "active-maps text-center " +
                (this.state.showArchivedMaps ? "" : "active")
              }
            >
              {t("Map:active-maps")}
            </div>
            <div
              onClick={() => {
                this.setState({ showArchivedMaps: true });
                this.props.getArchivedMaps();
              }}
              className={
                "archived-maps text-center " +
                (this.state.showArchivedMaps ? "active" : "")
              }
            >
              {t("Map:archived-maps")}
            </div>
          </div>
          {/* Maps */}
          <div className="maps">
            {this.state.showArchivedMaps === false ? (
              this.props.maps.length > 0 ? (
                this.props.maps.map((map) => {
                  return (
                    <MapShortcut
                      key={map.Id}
                      text={map.MainGoal}
                      Id={map.Id}
                      history={this.props.history}
                    />
                  );
                })
              ) : (
                <div
                  className="text-center text-disabled"
                  style={{ padding: "47.5px" }}
                >
                  <Link to="/create-map/choose-category">
                    <ButtonSmall primary text={t("Map:create-a-new-map")} />
                  </Link>
                </div>
              )
            ) : this.props.archivedMaps.length > 0 ? (
              this.props.archivedMaps.map((map) => {
                return (
                  <MapShortcut
                    key={map.Id}
                    text={map.MainGoal}
                    Id={map.Id}
                    history={this.props.history}
                  />
                );
              })
            ) : (
              <div
                className="text-center text-disabled"
                style={{ padding: "60px" }}
              >
                {t("Map:no-archived-maps")}
              </div>
            )}
          </div>
        </div>

        <div className="categories-container">
          <div className="titles">
            <span className="text-dark-blue" style={{ fontSize: "18px" }}>
              {t("Catagory:categories-i-achieved")}
            </span>
            {/* <span className="text-disabled">See all</span> */}
          </div>
          <div className="categories">
            {this.props.categories &&
              this.props.categories.map((category) => {
                return (
                  <CategoryShortcut
                    key={category.Id}
                    imgSrc={require(`images/categories/${category.Name}_${category.CompletedSteps}.png`)}
                    text={t(`Catagory:${category.Name}`)}
                  />
                );
              })}
            {/* <CategoryShortcut
              imgSrc={require("images/5.png")}
              text="Learning"
            />
            <CategoryShortcut imgSrc={require("images/6.png")} text="Family" />
            <CategoryShortcut imgSrc={require("images/4.png")} text="Career" />
            <CategoryShortcut
              imgSrc={require("images/1.png")}
              text="Wellbeing"
            />
            <CategoryShortcut imgSrc={require("images/3.png")} text="Other" /> */}
          </div>
        </div>

        {/* <Link to="/create-map/choose-category">
          <Button
            primary
            text="Create a new map"
            className="create-map-button"
          />
        </Link> */}

        <BottomBar />
      </div>
    );
  }

  toggleMyJourneyModal = () => {
    this.props.user &&
      this.setState({ myJourneyModalOpen: !this.state.myJourneyModalOpen });
  };

  // checkIfFirstLogin = () => {
  //   if (localStorage.getItem("first_login")) {
  //     // The user already logged in once, do nothing
  //   } else {
  //     // If first login
  //     this.toggleMyJourneyModal();
  //     localStorage.setItem("first_login", "true");
  //   }
  // };

  componentDidMount = () => {
    //this.props.getUser();

    this.props.resetMap();
    this.props.getCategories();
    this.props.getMaps();

    // this.checkIfFirstLogin();
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    categories: state.categoryReducer.categories,
    maps: state.mapReducer.maps,
    archivedMaps: state.mapReducer.archivedMaps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUser,
      getCategories,
      getMaps,
      getArchivedMaps,
      resetMap,
    },
    dispatch
  );
};
export default withTranslation("HomePage")(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
