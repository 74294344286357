import React, { Component } from "react";
import "./questions.scss";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  resetMap,
  getMapQuestions,
  changeBeforeMapAnswers,
} from "actions/map-actions";
//
import TopStrip from "../../../components/TopStrip/top-strip";
import BottomBar from "../../../components/BottomBar/bottom-bar";
import ButtonSmall from "../../../components/ButtonSmall/button-small";
import Modal from "../../../components/Modal/modal";
import { withTranslation } from "react-i18next";

class Questions extends Component {
  state = {};
  render() {
    const t = this.props.t;
    return (
      <div className="questions-page">
        <TopStrip />

        <div
          style={{
            padding: "10px 30px 15px 30px",
            marginTop: "20px",
          }}
        >
          <div style={{ fontSize: "18px" }} className="text-dark-blue">
            {t("create-map-modal.questions.before-we-get-started")}
          </div>
        </div>

        <div className="bg-content" style={{ padding: "30px" }}>
          {this.props.map.Questions &&
            this.props.map.Questions.BeforeMap.map((question, qIndex) => {
              return (
                <div key={question.Id} className="question-container fadeIn">
                  <div className="text-dark-blue" style={{ fontSize: "18px" }}>
                    {t(`Map:create-map-modal.questions.q${qIndex}`)}
                  </div>
                  <div className="answers">
                    {question.PossibleAnswers.map((possibleAnswer, index) => {
                      return (
                        <React.Fragment key={possibleAnswer.Id}>
                          <input
                            onClick={() =>
                              this.onSelectAnswer(
                                qIndex,
                                question.Id,
                                possibleAnswer.Id
                              )
                            }
                            className="answer"
                            type="radio"
                            value={possibleAnswer.Answer}
                            name={`question${qIndex}`}
                          />
                          <label htmlFor="male">
                            {t(`Map:create-map-modal.questions.a${index}`)}
                          </label>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <br />
                  <br />
                </div>
              );
            })}

          {this.props.map.BeforeMapAnswers && this.props.map.Questions && (
            <ButtonSmall
              className="float-right"
              primary
              text={`${t("Common:start")} >`}
              onClick={this.next}
              disabled={
                this.props.map.BeforeMapAnswers[0].SelectedAnswerId === "" ||
                this.props.map.BeforeMapAnswers[1].SelectedAnswerId === ""
              }
            />
          )}
        </div>

        <BottomBar />
      </div>
    );
  }

  componentDidMount = () => {
    // if not editing
    if (this.props.match.params.edit === undefined) {
      this.props.resetMap();
    }

    this.props.getMapQuestions();
  };

  onSelectAnswer = (questionIndex, questionId, selectedAnswerId) => {
    this.props.changeBeforeMapAnswers({
      questionIndex,
      questionId,
      selectedAnswerId,
    });
  };

  next = () => {
    this.props.history.push("/create-map/choose-category");
  };
}

const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetMap,
      getMapQuestions,
      changeBeforeMapAnswers,
    },
    dispatch
  );
};
export default withTranslation("Map")(
  connect(mapStateToProps, mapDispatchToProps)(Questions)
);
