const initialState = {
  maps: [],
  archivedMaps: [],
  map: {
    Questions: null,
    ImageIdFromGallery: "",
    BeforeMapAnswers: [
      {
        QuestionId: "",
        SelectedAnswerId: "",
      },
      {
        QuestionId: "",
        SelectedAnswerId: "",
      },
    ],
    AfterMapAnswers: [
      {
        QuestionId: "",
        SelectedAnswerId: "",
      },
      {
        QuestionId: "",
        SelectedAnswerId: "",
      },
    ],
    CategoryId: "",
    CategoryName: "",
    MainGoal: "",
    Word: "",
    Steps: [
      {
        Goal: "",
        Obstacle: "",
        Overcome: "",
        DueDate: null,
      },
      {
        Goal: "",
        Obstacle: "",
        Overcome: "",
        DueDate: null,
      },
      {
        Goal: "",
        Obstacle: "",
        Overcome: "",
        DueDate: null,
      },
    ],
    Base64Image: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "RESET_MAP":
      return { ...state, map: initialState.map };

    case "GET_MAPS":
      return { ...state, maps: action.payload };

    case "GET_ARCHIVED_MAPS":
      return { ...state, archivedMaps: action.payload };

    case "GET_MAP":
      return {
        ...state,
        map: {
          ...action.payload,
          BeforeMapAnswers: state.map.BeforeMapAnswers,
          AfterMapAnswers: state.map.AfterMapAnswers,
        },
      };

    case "GET_MAP_QUESTIONS":
      return {
        ...state,
        map: {
          ...state.map,
          Questions: action.payload,
        },
      };

    case "CHANGE_BEFORE_MAP_ANSWERS":
      return {
        ...state,
        map: {
          ...state.map,
          BeforeMapAnswers: state.map.BeforeMapAnswers.map((answer, index) => {
            if (index === action.payload.questionIndex) {
              return {
                QuestionId: action.payload.questionId,
                SelectedAnswerId: action.payload.selectedAnswerId,
              };
            } else {
              return answer;
            }
          }),
        },
      };

    case "CHANGE_AFTER_MAP_ANSWERS":
      return {
        ...state,
        map: {
          ...state.map,
          AfterMapAnswers: state.map.AfterMapAnswers.map((answer, index) => {
            if (index === action.payload.questionIndex) {
              return {
                QuestionId: action.payload.questionId,
                SelectedAnswerId: action.payload.selectedAnswerId,
              };
            } else {
              return answer;
            }
          }),
        },
      };

    case "CHANGE_MAP_CATEGORY":
      return {
        ...state,
        map: {
          ...state.map,
          CategoryId: action.payload.Id,
          CategoryName: action.payload.Name,
        },
      };

    case "CHANGE_MAP_MAIN_GOAL":
      return {
        ...state,
        map: {
          ...state.map,
          MainGoal: action.payload,
        },
      };

    case "CHANGE_MAP_WORD":
      return {
        ...state,
        map: {
          ...state.map,
          Word: action.payload,
        },
      };

    case "CHANGE_MAP_PAGE_IMAGE":
      return {
        ...state,
        map: {
          ...state.map,
          MapImage: action.payload,
        },
      };

    case "CHANGE_MAP_BASE64_IMAGE":
      return {
        ...state,
        map: {
          ...state.map,
          Base64Image: action.payload,
        },
      };

    case "CHANGE_IMAGE_ID_FROM_GALLERY":
      return {
        ...state,
        map: {
          ...state.map,
          ImageIdFromGallery: action.payload,
        },
      };

    case "CHANGE_MAP_STEP_GOAL":
      return {
        ...state,
        map: {
          ...state.map,
          Steps: state.map.Steps.map((step, index) => {
            if (index === action.payload.index) {
              return { ...step, Goal: action.payload.text };
            }
            return step;
          }),
        },
      };

    case "CHANGE_MAP_STEP_DUE_DATE":
      return {
        ...state,
        map: {
          ...state.map,
          Steps: state.map.Steps.map((step, index) => {
            if (index === action.payload.index) {
              return { ...step, DueDate: action.payload.date };
            }
            return step;
          }),
        },
      };

    case "CHANGE_MAP_STEP_OBSTACLE":
      return {
        ...state,
        map: {
          ...state.map,
          Steps: state.map.Steps.map((step, index) => {
            if (index === action.payload.index) {
              return { ...step, Obstacle: action.payload.text };
            }
            return step;
          }),
        },
      };

    case "CHANGE_MAP_STEP_OVERCOME":
      return {
        ...state,
        map: {
          ...state.map,
          Steps: state.map.Steps.map((step, index) => {
            if (index === action.payload.index) {
              return { ...step, Overcome: action.payload.text };
            }
            return step;
          }),
        },
      };

    case "UPDATE_STEP":
      return {
        ...state,
        map: { ...state.map, Steps: action.payload.Steps },
      };

    default:
      return state;
  }
}
