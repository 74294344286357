import React, { Component } from "react";
import "./list-view.scss";
// Redux
import { connect } from "react-redux";
//
import TextArea from "../../../components/TextArea/text-area";
import { withTranslation } from "react-i18next";

class ListView extends Component {
  state = {
    currentStep: 0,
  };
  render() {
    const t = this.props.t;
    return (
      <div className="list-view">
        <br />
        {this.props.map.FinishedAllSteps && (
          <div className="text-center text-dark-blue font-weight-bold">
            <i className="fa fa-check" style={{ color: "green" }}></i>{" "}
            {t("Journey:journey-completed")}
          </div>
        )}
        <div style={{ padding: "40px 20px" }} className="fadeIn">
          <div className="step-images-container">
            <div
              className={
                "step-image " + (this.state.currentStep === 0 && "active")
              }
            >
              <img
                onClick={() => {
                  this.changeCurrentStep(0);
                }}
                src={require("images/steps/green1.png")}
              />
            </div>
            <div
              className={
                "step-image " + (this.state.currentStep === 1 && "active")
              }
            >
              <img
                onClick={() => {
                  this.changeCurrentStep(1);
                }}
                src={require("images/steps/green2.png")}
              />
            </div>
            <div
              className={
                "step-image " + (this.state.currentStep === 2 && "active")
              }
            >
              <img
                onClick={() => {
                  this.changeCurrentStep(2);
                }}
                src={require("images/steps/green3.png")}
              />
            </div>
          </div>
          <div className="step-goal-text text-dark-blue">
            {this.props.map.Steps[this.state.currentStep].DueDate && (
              <span className="text-disabled" style={{ fontSize: "14px" }}>
                {new Date(
                  this.props.map.Steps[this.state.currentStep].DueDate
                ).toLocaleDateString()}
                <br />
              </span>
            )}
            {this.props.map.Steps[this.state.currentStep].Goal}
          </div>
          <br />
          <TextArea
            readOnly
            icon={require("images/steps/obstacle.png")}
            id="text-area"
            placeholder={t("Map:croc-modal-step.block-you-placeholder")}
            style={{ width: "100%", marginBottom: "10px" }}
          >
            {this.props.map.Steps[this.state.currentStep].Obstacle}
          </TextArea>
          <TextArea
            readOnly
            icon={require("images/steps/overcome.png")}
            id="text-area"
            placeholder={t("croc-modal-step.deal-obstacle-placeholder")}
            style={{ width: "100%" }}
          >
            {this.props.map.Steps[this.state.currentStep].Overcome}
          </TextArea>
        </div>
      </div>
    );
  }

  changeCurrentStep = (stepNum) => {
    // if you press the flag
    this.setState({
      currentStep: stepNum,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
  };
};

export default withTranslation("Map")(connect(mapStateToProps, null)(ListView));
