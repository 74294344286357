import Api from "../utils/api";

export const getUser = () => {
  return (dispatch, getState) => {
    return Api.get("User/Profile").then((res) => {
      const {
        data: { Success, Payload, Message, ErrorCode },
      } = res;
      if (Success) {
        dispatch({
          type: "GET_USER",
          payload: {
            firstName: Payload.FirstName,
            lastName: Payload.LastName,
            isAdmin: Payload.IsAdmin,
            Settings: Payload.Settings,
          },
        });
      }
    });
  };
};
export const setUser = (Payload) => {
  return {
    type: "GET_USER",
    payload: {
      firstName: Payload.FirstName,
      lastName: Payload.LastName,
      isAdmin: Payload.IsAdmin,
      Settings: Payload.Settings,
    },
  };
};
export const logIn = () => {
  return {
    type: "SET_LOGIN",
    payload: true,
  };
};

export const editReminderCycle = (notificationsSettings) => {
  return (dispatch, getState) => {
    return Api.put(
      "/User/Profile/Settings/Notifications",
      notificationsSettings
    ).then((res) => {
      const {
        data: { Success, Payload, Message, ErrorCode },
      } = res;
      if (Success) {
        dispatch({
          type: "EDIT_REMINDER_CYCLE",
          payload: Payload.Settings.Notifications,
        });
      }
    });
  };
};

export const logOut = () => {
  return (dispatch, getState) => {
    return Api.get("Logout").then((res) => {
      const {
        data: { Success, Payload, Message, ErrorCode },
      } = res;
      if (Success) {
        dispatch({
          type: "SET_LOGIN",
          payload: false,
        });
      }
    });
  };
};
