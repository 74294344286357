import React, { Component } from "react";
import "./enter-steps.scss";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeMapStepGoal, changeMapStepDueDate } from "actions/map-actions";
//
import BottomBar from "../../../components/BottomBar/bottom-bar";
import Input from "../../../components/Input/input";
import ButtonSmall from "../../../components/ButtonSmall/button-small";
import moment from "moment";
import CategoryShortcut from "../../../components/CategoryShortcut/category-shortcut";
import TopStrip from "../../../components/TopStrip/top-strip";
import Modal from "components/Modal/modal";
import { editReminderCycle } from "actions/user-actions";
import { withTranslation } from "react-i18next";

class EnterSteps extends Component {
  state = {
    currentStep: 0,
    setNotioficationsModalOpen: false,
    notificationCycle: 7,
  };

  render() {
    const t = this.props.t;
    return (
      <div className="enter-steps">
        <TopStrip />
        <div
          style={{
            padding: "10px 30px 15px 30px",
            marginTop: "20px",
            fontSize: "18px",
          }}
          className="text-disabled"
        >
          <div style={{ marginBottom: "10px" }}>
            {t("map-catagory")}:{" "}
            <strong>{t(`Catagory:${this.props.map.CategoryName}`)}</strong>
          </div>
          <div style={{ marginBottom: "10px" }}>
            {t("main-goal")}: <strong>{this.props.map.MainGoal}</strong>{" "}
          </div>
          <span className="fadeIn">{t("map-image")}</span>
          <br />
        </div>
        <div className="bg-content" style={{ padding: "20px 30px" }}>
          <div className="text-dark-blue" style={{ fontSize: "20px" }}>
            {t("create-map-modal.enter-steps.steps")}
          </div>
          <p style={{ marginBottom: "25px" }}>
            {t("create-map-modal.enter-steps.text1")}
          </p>
          {t("Map:create-map-modal.enter-steps.text2")}
          {t(`create-map-modal.enter-steps.${this.getStageText()}`)}{" "}
          {t("create-map-modal.enter-steps.text3")}
          <br />
          <br />
          <br />
          <div className="step-images-container">
            <div
              className={
                "step-image " + (this.state.currentStep === 0 && "active")
              }
            >
              <img
                onClick={() => {
                  this.changeCurrentStep(0);
                }}
                src={require("images/steps/green1.png")}
              />
            </div>
            <div
              className={
                "step-image " + (this.state.currentStep === 1 && "active")
              }
            >
              <img
                onClick={() => {
                  this.changeCurrentStep(1);
                }}
                src={require("images/steps/green2.png")}
              />
            </div>
            <div
              className={
                "step-image " + (this.state.currentStep === 2 && "active")
              }
            >
              <img
                onClick={() => {
                  this.changeCurrentStep(2);
                }}
                src={require("images/steps/green3.png")}
              />
            </div>
          </div>
          <br />
          <Input
            id="input"
            value={this.props.map.Steps[this.state.currentStep].Goal}
            placeholder={t("create-map-modal.enter-steps.i-will")}
            style={{ width: "100%" }}
            onChange={(e) => {
              this.props.changeMapStepGoal(
                this.state.currentStep,
                e.target.value
              );
            }}
          />
          <Input
            type="date"
            className={
              this.props.map.Steps[this.state.currentStep].DueDate
                ? "has-value"
                : ""
            }
            value={moment(
              this.props.map.Steps[this.state.currentStep].DueDate
            ).format("YYYY-MM-DD")}
            // placeholder="By when... (Optional)"
            placeholder={`${t(
              "create-map-modal.enter-steps.i-will-start"
            )} (${t("Common:optional")})`}
            style={{ width: "100%", marginTop: "10px" }}
            onChange={(e) => {
              this.props.changeMapStepDueDate(
                this.state.currentStep,
                e.target.value
              );
            }}
          />
          {/* Due date google calendar */}
          <div style={{ margin: "10px" }}></div>
          {this.props.map.Steps[this.state.currentStep].DueDate && (
            <a
              className="add-to-calendar"
              target="_blank"
              href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${this.props.map.Steps[
                this.state.currentStep
              ].Goal.split(" ").join(
                "+"
              )}&details=A+reminder+to+log+in+to+the+hopetimize+app&dates=undefined%2F${
                //   new Date(
                //     this.props.map.Steps[this.state.currentStep].DueDate
                //   ).toLocaleDateString() //change to locale date
                moment()
                  .format(this.props.map.Steps[this.state.currentStep].DueDate)
                  .replace(/-|:|\.\d\d\d/g, "")
              }`}
            >
              {t("create-map-modal.enter-steps.add-to-google-calendar")}
            </a>
          )}
          {/* Next button */}
          <ButtonSmall
            style={{ marginTop: "10px" }}
            className="float-right"
            primary
            small
            text={`${t("Common:next")} >`}
            onClick={() => this.changeCurrentStep(this.state.currentStep + 1)}
            disabled={
              this.props.map.Steps[this.state.currentStep].Goal.length === 0
              //|| this.props.map.Steps[this.state.currentStep].DueDate === ""
            }
          />
        </div>

        {this.state.setNotioficationsModalOpen && (
          <Modal
            title={t("create-map-modal.enter-steps.set-a-remider")}
            noCloseBtn
          >
            {t("create-map-modal.enter-steps.notification-title")}
            <br />
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              {/* <input type="checkbox" style={{ margin: "0px" }} /> */}
              <span className="font-weight-bold">Set a reminder every</span>
              <select
                value={this.state.reminderCycle}
                onChange={(e) => {
                  this.setState({ reminderCycle: e.target.value });
                }}
              >
                <option value="7">
                  {t("create-map-modal.enter-steps.7-days")}
                </option>
                <option value="14">
                  {t("create-map-modal.enter-steps.14-days")}
                </option>
                <option value="30">
                  {t("create-map-modal.enter-steps.30-days")}
                </option>
              </select>
            </div>
            <br />
            <ButtonSmall
              primary
              text={t("Common:confirm")}
              style={{ marginBottom: "10px" }}
              onClick={() => {
                this.props
                  .editReminderCycle({
                    Active: true,
                    ReminderCycle: this.state.reminderCycle,
                  })
                  .then(this.nextPage);
              }}
            />
            <ButtonSmall
              text={t("create-map-modal.enter-steps.no-reminder")}
              onClick={this.nextPage}
            />
          </Modal>
        )}

        <BottomBar />
      </div>
    );
  }

  getStageText = () => {
    switch (this.state.currentStep) {
      case 0:
        return "first";

      case 1:
        return "second";

      case 2:
        return "third";

      default:
        return "first";
    }
  };

  changeCurrentStep = (stepNum) => {
    if (stepNum < 3) {
      document.getElementById("input").focus();

      // if you press the flag
      this.setState({
        currentStep: stepNum,
      });
    } else {
      if (!this.props.user.Settings.Notifications.SettedByUser) {
        // if (this.props.user.Settings.Notifications.SettedByUser) { for testing
        this.setState({ setNotioficationsModalOpen: true });
      } else {
        this.nextPage();
      }
    }
  };

  nextPage = () => {
    // Move to the next page
    this.props.history.push(
      this.props.match.params.edit !== undefined
        ? "/create-map/enter-obstacles-and-overcomes/edit"
        : "/create-map/enter-obstacles-and-overcomes"
    );
  };

  componentDidMount = () => {
    if (!this.props.map.CategoryId)
      this.props.history.push(
        this.props.match.params.edit !== undefined
          ? "/home"
          : "/create-map/questions"
      );
  };
}

const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
    categories: state.categoryReducer.categories,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeMapStepGoal,
      changeMapStepDueDate,
      editReminderCycle,
    },
    dispatch
  );
};
export default withTranslation("Map")(
  connect(mapStateToProps, mapDispatchToProps)(EnterSteps)
);
