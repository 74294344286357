const initialState = {
  users: null,
  userCreating: {
    FirstName: "",
    LastName: "",
    Phone: {
      Number: "",
      CountryCode: "",
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_USERS":
      return { ...state, users: action.payload.docs };

    case "CHANGE_USER_CREATING":
      return { ...state, userCreating: action.payload };

    case "RESET_USER_CREATING":
      return { ...state, userCreating: initialState.userCreating };

    default:
      return state;
  }
}
