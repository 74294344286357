import React, { Component } from "react";
import "./map-view.scss";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateStep, changeMapPageImage } from "actions/map-actions";
//
import Modal from "components/Modal/modal";
import TextArea from "../../../components/TextArea/text-area";
import ButtonSmall from "../../../components/ButtonSmall/button-small";
import CategoryShortcut from "../../../components/CategoryShortcut/category-shortcut";
import Api from "utils/api";
import { withTranslation } from "react-i18next";

class MapView extends Component {
  state = {
    crocModalStep: null,
    imageRand: Date.now(),
  };

  changeProfileImage = (el) => {
    if (this.image.files.length == 1) {
      var img = new Image();
      img.onload = () => {
        const height = 200;
        const width = 200;
        let canvasHeight = 0;
        let canvasWidth = 0;
        let ratio = img.width / img.height;
        if (img.width > img.height) {
          canvasWidth = width;
          canvasHeight = width / ratio;
        } else {
          canvasWidth = height * ratio;
          canvasHeight = height;
        }
        const canvas = document.createElement("canvas");
        canvas.height = canvasHeight; //get original canvas height
        canvas.width = canvasWidth; // get original canvas width
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, canvasWidth, canvasHeight);
        this.setState({ img: canvas.toDataURL() });
        Api.post(`/User/Maps/${this.props.map.Id}/Image`, {
          Base64Image: canvas.toDataURL(),
        }).then((res) => {
          this.setState({ imageRand: Date.now() });
          this.props.changeMapPageImage("true");
        });
        // alert("the image is drawn");
      };
      img.onerror = (err) => {
        alert("this is not an image");
      };
      img.src = URL.createObjectURL(el.target.files[0]);
    } else {
      return;
    }
  };
  render() {
    const t = this.props.t;
    return (
      <div className="map-view fadeIn">
        {/* <div className="overcomes-container">
          {this.props.map.Steps.map((step, index) => {
            return (
              <img
                onClick={() =>
                  !this.props.map.IsArchived &&
                  this.props.completedSteps != 3 &&
                  this.props.updateStep({
                    IsAcomplished: !step.IsAcomplished,
                    MapId: this.props.map.Id,
                    StepIndex: index,
                  })
                }
                src={require(`images/map/bridge${index}.png`)}
                key={index}
                className={step.IsAcomplished ? "disabled" : ""}
              />
            );
          })}
        </div> */}

        <div className="map">
          {this.props.map.AfterMapAnswers[0].QuestionId === "" &&
          this.props.map.IsArchived === false ? (
            <img
              src={require(`images/map/${this.props.completedSteps}.png`)}
              className="map-image fadeInDown"
            />
          ) : this.props.map.Questions ? (
            <img
              src={require(`images/map/confetti.gif`)}
              className="map-image fadeInDown"
            />
          ) : (
            <img
              src={require(`images/map/0.png`)}
              className="map-image fadeInDown"
            />
          )}

          <div
            className="main-goal-floating-text font-weight-bold"
            style={
              this.props.map.MapImage ? { bottom: "92.5%", left: "70%" } : {}
            }
          >
            {this.props.map.Word && (
              <React.Fragment>
                <span className="word">{this.props.map.Word}</span>
                <br />
              </React.Fragment>
            )}

            {this.props.map.MainGoal}
          </div>
          {this.props.map.MapImage && (
            <React.Fragment>
              <input
                onChange={(el) => this.changeProfileImage(el)}
                type="file"
                accept="image/*"
                ref={(el) => (this.image = el)}
                style={{ display: "none" }}
              />
              <CategoryShortcut
                onClick={() => this.image.click()}
                imgSrc={`Api/User/Maps/${this.props.map.Id}/Image?${this.state.imageRand}`}
                className="user-map-img"
                imgStyle={{
                  borderRadius: "50%",
                  border: "2px solid #fafafa",
                  boxShadow: "2px 2px rgba(0,0,0,0.1)",
                  objectFit: "cover",
                }}
              />
            </React.Fragment>
          )}

          {/* Flag Text */}
          {this.props.map.Steps.map((step, index) => {
            return (
              <div key={index} className={`flag-text flag-text-${index}`}>
                {step.DueDate && (
                  <span className="text-disabled">
                    {new Date(step.DueDate).toLocaleDateString()}
                    <br />
                  </span>
                )}
                {step.Goal}
              </div>
            );
          })}

          {/* Crocodile Buttons */}
          {this.props.map.Steps.map((step, index) => {
            return (
              <div
                key={index}
                onClick={() => this.showCrocModal(step, index)}
                className={`croc-btn croc-btn-${index}`}
              />
            );
          })}
        </div>

        {this.state.crocModalStep && (
          <Modal
            close={this.closeCrocModal}
            title={
              this.state.crocModalStep.IsAcomplished
                ? `${t("croc-modal-step.step-completed")}!`
                : t("croc-modal-step.complete-step")
            }
          >
            <TextArea
              readOnly
              icon={require("images/steps/obstacle.png")}
              id="text-area"
              placeholder={t("croc-modal-step.block-you-placeholder")}
              style={{ width: "100%", marginBottom: "10px" }}
            >
              {this.state.crocModalStep.Obstacle}
            </TextArea>
            <TextArea
              readOnly
              icon={require("images/steps/overcome.png")}
              id="text-area"
              placeholder={t("croc-modal-step.deal-obstacle-placeholder")}
              style={{ width: "100%" }}
            >
              {this.state.crocModalStep.Overcome}
            </TextArea>
            <br />

            {!this.state.crocModalStep.IsAcomplished && (
              <React.Fragment>
                <div className="text-disabled">
                  {t("croc-modal-step.question")}
                </div>
                <br />
                <div
                  style={{
                    textAlign: this.props.langCode === "he" ? "right" : "left",
                  }}
                >
                  <ButtonSmall
                    style={{ marginBottom: "10px" }}
                    primary
                    text={t("croc-modal-step.i-made-it")}
                    onClick={() => {
                      this.closeCrocModal();
                      !this.props.map.IsArchived &&
                        this.props.completedSteps != 3 &&
                        this.props
                          .updateStep({
                            IsAcomplished: true,
                            MapId: this.props.map.Id,
                            StepIndex: this.state.crocModalStep.index,
                          })
                          .then(() => {
                            if (this.props.map.Steps[2].IsAcomplished) {
                              this.props.showAfterMapQuestionsModal();
                            }
                          });
                    }}
                  />
                  <br />
                  <ButtonSmall
                    style={{ marginBottom: "10px" }}
                    text={t("croc-modal-step.i-cant-made-it")}
                    onClick={() => {
                      this.props.history.push(
                        "/create-map/enter-obstacles-and-overcomes/edit"
                      );
                    }}
                  />
                  <br />
                  {/* <ButtonSmall
                    text="I need more time"
                    onClick={() => {
                      this.props.history.push("/create-map/enter-steps/edit");
                    }}
                  /> */}
                </div>
              </React.Fragment>
            )}
          </Modal>
        )}
      </div>
    );
  }

  showCrocModal = (step, index) => {
    this.setState({ crocModalStep: { ...step, index } });
  };

  closeCrocModal = () => {
    this.setState({ crocModalStep: null });
  };
}

const mapStateToProps = (state) => {
  return {
    map: state.mapReducer.map,
    langCode: state.settingsReducer.langCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateStep,
      changeMapPageImage,
    },
    dispatch
  );
};
export default withTranslation("Map")(
  connect(mapStateToProps, mapDispatchToProps)(MapView)
);
