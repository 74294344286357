import React, { Component } from "react";
import "./admin-home-page.scss";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getStats } from "actions/admin/panel-actions";
import {
  changeUserCreating,
  createUser,
  getUsers,
  resetUserCreating,
} from "actions/admin/manage-users-actions";
//
import BottomBar from "components/BottomBar/bottom-bar";
import ButtonSmall from "components/ButtonSmall/button-small";
import CountUp from "react-countup";
import Modal from "components/Modal/modal";
import Input from "components/Input/input";
import PhoneInput from "react-phone-input-2";
import TopStrip from "components/TopStrip/top-strip";
import { withTranslation } from "react-i18next";

class AdminHomePage extends Component {
  state = {
    createUserModalOpen: false,
    createUserBtnDisabled: false,
    userCreatedSuccessfullyModal: false,
  };
  render() {
    const t = this.props.t;
    return (
      <div className="admin-home-page">
        <TopStrip smaller noImg title={t("admin.admin-panel")} />

        <div
          className="text-dark-blue text-center font-weight-bold"
          style={{ fontSize: "22px", margin: "-20px 0px 15px" }}
        >
          {t("admin.heres-whats-going")}
        </div>
        {this.props.adminPanelStats && (
          <div>
            <div className="layout-container">
              {/* Total Maps */}
              <div className="box fadeIn">
                <div className="box-title">
                  <CountUp
                    delay={0.25}
                    end={this.props.adminPanelStats.TotalMaps}
                  />
                </div>
                {t("admin.open-maps")}
              </div>

              {/* Active Users */}
              <div className="box fadeIn">
                <div className="box-title">
                  <CountUp
                    delay={0.25}
                    end={this.props.adminPanelStats.ActiveUsers}
                  />
                </div>
                {t("admin.active-users")}
              </div>

              {/* New users last 7 days */}
              <div className="box fadeIn">
                <div className="box-title">
                  <CountUp
                    delay={0.25}
                    end={this.props.adminPanelStats.NewUsersLast7Days}
                  />
                </div>
                {t("admin.new-users-last-7-days")}
              </div>

              {/* New users last 30 days */}
              <div className="box fadeIn">
                <div className="box-title">
                  <CountUp
                    delay={0.25}
                    end={this.props.adminPanelStats.NewUsersLast30Days}
                  />
                </div>
                {t("admin.new-users-last-30-days")}
              </div>
            </div>
            {/* end Layout container */}

            <ButtonSmall
              primary
              text={t("admin.create-new-user")}
              className="create-user-btn fadeIn"
              onClick={this.openCreateUserModal}
            />
            <ButtonSmall
              primary
              text={t("admin.view-all-users")}
              className="create-user-btn fadeIn"
              onClick={() => {
                this.props.history.push("/admin/users");
              }}
            />
            <br />
            <br />
          </div>
        )}

        {/* Create new user modal */}
        {this.state.createUserModalOpen && (
          <Modal
            modalStyle={{ overflow: "visible" }}
            close={this.closeCreateUserModal}
            title={t("admin.create-new-user")}
          >
            <Input
              className="create-user-input"
              placeholder={`${t("admin.create-new-user-modal.first-name")}...`}
              value={this.props.userCreating.FirstName}
              onChange={(e) => {
                this.props.changeUserCreating({
                  ...this.props.userCreating,
                  FirstName: e.target.value,
                });
              }}
            />
            <Input
              className="create-user-input"
              placeholder={`${t("admin.create-new-user-modal.last-name")}...`}
              value={this.props.userCreating.LastName}
              onChange={(e) => {
                this.props.changeUserCreating({
                  ...this.props.userCreating,
                  LastName: e.target.value,
                });
              }}
            />
            <PhoneInput
              style={{ margin: "12.5px auto 0px auto" }}
              disabled={false}
              containerClass="phone"
              inputClass="phone-input"
              buttonClass="phone-button"
              dropdownClass="phone-select"
              enableSearch={true}
              countryCodeEditable={false}
              country={"us"}
              value={
                this.props.userCreating.Phone.CountryCode +
                this.props.userCreating.Phone.Number
              }
              onChange={(phone, country) => {
                this.props.changeUserCreating({
                  ...this.props.userCreating,
                  Phone: {
                    Number: phone.slice(country.dialCode.length),
                    CountryCode: country.dialCode,
                  },
                });
              }}
            />

            <br />
            <ButtonSmall
              disabled={this.isCreateUserBtnDisabled()}
              primary
              text={t("admin.create-new-user-modal.create-user")}
              className="create-user-btn"
              onClick={() => {
                this.setState({ createUserBtnDisabled: true });
                this.props.createUser(this.props.userCreating).then((res) => {
                  if (res.Success) {
                    this.setState({ createUserBtnDisabled: false });
                    this.openUserCreatedSuccessfullyModal();
                    this.closeCreateUserModal();
                  }
                });
              }}
            />
          </Modal>
        )}

        {/* User created successfully modal*/}
        {this.state.userCreatedSuccessfullyModal && (
          <Modal
            close={this.closeUserCreatedSuccessfullyModal}
            title={t("admin.user-created-successfully-modal.title")}
          >
            {t("admin.user-created-successfully-modal.text")}
            <ButtonSmall
              primary
              text={t("close")}
              className="create-user-btn"
              onClick={this.closeUserCreatedSuccessfullyModal}
            />
          </Modal>
        )}
        <BottomBar />
      </div>
    );
  }

  isCreateUserBtnDisabled = () => {
    if (this.state.createUserBtnDisabled) return true;

    if (
      this.props.userCreating.FirstName === "" ||
      this.props.userCreating.LastName === "" ||
      this.props.userCreating.Phone.Number.length === 0
    )
      return true;

    return false;
  };

  openCreateUserModal = () => {
    this.setState({ createUserModalOpen: true });
  };

  closeCreateUserModal = () => {
    this.props.resetUserCreating();
    this.setState({ createUserModalOpen: false });
  };

  openUserCreatedSuccessfullyModal = () => {
    this.setState({ userCreatedSuccessfullyModal: true });
  };

  closeUserCreatedSuccessfullyModal = () => {
    this.props.resetUserCreating();
    this.setState({ userCreatedSuccessfullyModal: false });
    this.props.getStats();
  };

  componentDidMount = () => {
    this.props.getStats().then(() => {
      console.log(this.props.adminPanelStats);
    });
  };
}

const mapStateToProps = (state) => {
  return {
    adminPanelStats: state.panelReducer.adminPanelStats,
    userCreating: state.manageUsersReducer.userCreating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStats,
      changeUserCreating,
      resetUserCreating,
      createUser,
      getUsers,
    },
    dispatch
  );
};
export default withTranslation("HomePage")(
  connect(mapStateToProps, mapDispatchToProps)(AdminHomePage)
);
