import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./bottom-bar.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logOut } from "actions/user-actions";
class BottomBar extends Component {
  state = {};
  logOut = () => {
    this.props.logOut();
  };
  render() {
    return (
      <React.Fragment>
        <div className="bottom-bar-margin" />
        <div className="bottom-bar">
          <span
            style={{ color: "#888", textDecoration: "none" }}
            onClick={() => this.logOut()}
          >
            <img src={require("images/log out.png")} />
          </span>
          {/* <NavLink to="/forum"> */}
          {/* <NavLink to="#">
            <img
              src={
                window.location.hash.split("#/")[1].split("/")[0] === "forum"
                  ? require("images/forum2.png")
                  : require("images/forum1.png")
              }
            />
          </NavLink> */}
          <NavLink
            to={
              this.props.maps.length > 0
                ? "/map/" + this.props.maps[0].Id
                : "/create-map/questions"
            }
          >
            <img
              src={
                window.location.hash.split("#/")[1].split("/")[0] === "map"
                  ? require("images/map2.png")
                  : require("images/map1.png")
              }
            />
          </NavLink>

          <NavLink to="/create-map/questions">
            <img
              src={
                window.location.hash.split("#/")[1].split("/")[0] ===
                "create-map"
                  ? require("images/plus2.png")
                  : require("images/plus1.png")
              }
            />
          </NavLink>

          <NavLink to="/settings">
            <img
              src={
                window.location.hash.split("#/")[1].split("/")[0] === "settings"
                  ? require("images/settings2.png")
                  : require("images/settings1.png")
              }
            />
          </NavLink>

          <NavLink to="/home">
            <img
              src={
                window.location.hash.split("#/")[1].split("/")[0] === "home"
                  ? require("images/profile2.png")
                  : require("images/profile1.png")
              }
            />
          </NavLink>

          {this.props.isAdmin && (
            <React.Fragment>
              {/* manage users */}
              <NavLink to="/admin/users">
                <img
                  src={
                    window.location.hash.split("#/")[1] === "admin/users"
                      ? require("images/users2.png")
                      : require("images/users1.png")
                  }
                />
              </NavLink>
              {/* admin panel */}
              <NavLink to="/admin/home">
                <img
                  src={
                    window.location.hash.split("#/")[1] === "admin/home"
                      ? require("images/dashboard2.png")
                      : require("images/dashboard1.png")
                  }
                />
              </NavLink>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    maps: state.mapReducer.maps,
    isAdmin: state.userReducer.user.isAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logOut,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(BottomBar);
